.o-table {
    // border:solid 1px $light-gray;
    border-radius: var(--border-radius-lg);
    td {
        padding:var(--spacing-md) var(--spacing-md);
        border-bottom:solid 1px var(--neutral-300);
    }
    th {
        padding: var(--spacing-xs) var(--spacing-md);
        // border-bottom:solid 2px darken($bright-blue, 3%);
        // border-top:solid 2px $light-medium-gray;
        border-bottom:solid 2px var(--neutral-500);
        // color: darken($bright-blue, 20%);
        color:var(--neutral-800);
        // background-color:lighten($bright-blue, 53%) !important;
        // background-color:$lightest-gray !important;
        font-weight:var(--font-default-weight);
    } 
}

.o-table {
    &--striped {
        tr:nth-child(even) {
            background-color: var(--neutral-100);
          }
    }
}