.o-modal {
    @extend .modal;
    z-index: 2001;
    &__dialog {
        @extend .modal-dialog;
        background-color: var(--surface-body-bg);
        border-radius:var(--border-radius-lg);
        padding: 0;
        max-width:800px;
        // &--wide {
        //     max-width: 800px;
        // }
    }
    &__content {
        @extend .modal-content;
    }
    &__header {
        @extend .modal-header;
        padding-left:var(--spacing-xxl);
        padding-right:var(--spacing-xxl); 
        padding-top:var(--spacing-xl);
        border-bottom: none;
    }
    &__title {
        @extend .modal-title;
    }
    &__body {
        @extend .modal-body;
        padding-left:var(--spacing-xxl);
        padding-right:var(--spacing-xxl);
        
    }
    &__footer {
        @extend .modal-footer;
        border-top:none;
        padding-left:var(--spacing-xxl);
        padding-right:var(--spacing-xxl);
        
    }
}

.modal-backdrop {
    backdrop-filter: blur(40px) brightness(70%);
    -webkit-backdrop-filter: blur(40px) brightness(80%);
    background-color:transparent;
    z-index: 2000;
}

.modal-backdrop.show {
    opacity: 0.6;
}

