.m-action-dropdown {
   display: inline-flex;
   align-items: center;
   justify-content: flex-start;
   &__label {
    background-color:var(--surface-body-bg);
    height:100%;
    margin-bottom: 0;
    padding:.7rem var(--spacing-xxs) var(--spacing-xs) var(--spacing-sm);
   }
    &__select {
        background-color:var(--surface-body-bg);
        height:100%;
        padding:var(--spacing-xs) var(--spacing-3xl) var(--spacing-xs) var(--spacing-xxs);
        font-weight:var(--font-default-weight);
        border-radius:0px;
        border:none;
        transition: all .3s ease-out;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        background-image:url('../images/dropdown-arrow.png');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 95% center;
        color:var(--color-action-color);
        
        font-weight:var(--font-bold-weight);
    }
    &__option
    {
        color: var(--text-body-text);
        font-weight:var(--font-default-weight);
    }
    &__button {
        height:100%;
    }
}