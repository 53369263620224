.m-image-block {
    background-color: var(--surface-low-contrast);
    background-position: center center;
    background-size:cover;
    position: relative;
    &__overlay {
        height:100%;
        width:100%;
        position: absolute;
        top:0;
        left:0;
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 51%,transparent 100%);
        opacity: 0.7;
        z-index:1;
        transition: all 0.3s ease-out;
        z-index:1;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding:var(--spacing-xxl);
        height:100%;
        // background: linear-gradient(180deg, rgba(32,32,32,0) 40%, rgba(32,32,32,0.4) 80%, rgba(32,32,32,0.7591680617559523) 100%);
        border-radius: var(--border-radius-lg);
        z-index: 5;
    }
    &__title {
        font-family: var(--body-fonts);
        font-size:var(--font-xl-size);
        line-height: var(--line-height-spacious);
        color:var(--text-body-text-inverse);
        z-index: 5;
    }
    &__text {
        color: var(--text-body-text-inverse);
        z-index: 5;
    }
    &__link {

        color:var(--text-body-text-inverse);
        z-index: 5;
        cursor: pointer;
    }
    &__img {
        max-width:64px;
        margin-bottom:var(--spacing-xl);
        z-index: 5;
    }
    &__tag {
        @include eyebrow--white;
        z-index: 5;
        margin-bottom: 0;
    }
}

.m-image-block:hover {
    -webkit-box-shadow: 2px 2px 7px 3px rgba(0,0,0,0.2);
        box-shadow: 0px 2px 7px 3px rgba(0,0,0,0.2);
    .m-image-block__overlay {
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 51%,transparent 100%);
        opacity: 1;
    }
    .m-image-block__text {
        color:var(--text-body-text-inverse)!important;
    }
    .m-image-block__link {
        color:var(--text-body-text-inverse)!important;
        text-decoration: underline;
    }
}

//REVIEW
