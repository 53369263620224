.m-icon-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    &--right-align {
        justify-content: flex-end;
    }
    &__icon {
        margin:4px;
    }
    &__icon:last-of-type {
        margin-right:0px;
    }
}