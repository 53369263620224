.m-section-heading {
    border-bottom: solid 1px var(--line-low-contrast);
    position: relative;
    text-align: center;
    margin:var(--spacing-xxl) 0 var(--spacing-xl) 0;
    &__inner {
        transform: translate(0px, 43px);
        background-color: var(--surface-body-bg);
        display: inline-block !important;
        padding:var(--spacing-md);
    }
}