.m-announcement {
    padding:var(--spacing-xl) var(--spacing-md);
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width:993px) {
        justify-content: flex-start;
    }
    // -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15);
    // box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.15);
    &--gray {
        background-color:var(--surface-low-contrast);
    }
    @media (max-width:993px) {
        flex-direction: column;
        align-items: flex-start;
    }
    &__img {
        flex-basis:64px;
        width:64px;
        height:64px;
        margin-right:2rem;
        @media (max-width:993px) {
            text-align: left;

            margin-bottom:var(--spacing-md);
        }
    }
    &__title {
        @include heading-3;
    }
    &__message {
        padding-right:10%;
    }
    &__cta {
        @extend .a-button;
        &:hover {
            color:var(--color-action-color)!important;
        }
    }
}