.m-testimonial-block
{
    &--centered
    {
        text-align: center;
    }
    &__img
    {
        //max-width:128px !important;
        width:40% !important;
        display: block;
        margin-bottom: var(--spacing-xs);
    }
    &__img--wide
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--32
    {
        max-width:32px !important;
        width:32px !important;
    }
    &__img--x-small
    {
        max-width:48px !important;
        width:48px !important;
    }
    &__img--small
    {
        max-width:64px !important;
        width:64px !important;
    }
    &__img--medium
    {
        max-width:96px !important;
        width:96px !important;
    }
    &__img--large
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--full-width
    {
        max-width:80% !important;
        width:80% !important;
    }
   
        &__title
    {
        @include heading-3;
        
    }

    &__quote {
        @include pull-quote;
    }

    
    &__text
    {
        font-size:var(--font-md-size);
        padding-right:var(--spacing-sm);
        white-space: pre-wrap;
    }
    &__text:last-of-type
    {
        margin-bottom:var(--spacing-xs)
    }
    &__link
    {
        
        margin-top:var(--spacing-md);
        display: inline-block;
    }
    &__subtitle
    {
        color:var(--text-accent);
        margin-bottom:0;
        padding:0rem 0rem;
        display: inline-block;
        border-radius:var(--border-radius-sm);
        font-weight:var(--font-bold-weight);
        font-size:var(--font-md-size);
        &--icon-matched {
            color:var(--color-light-accent-color);
        }
        
    }
}