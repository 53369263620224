.o-featured-video {
    &__title {
        @include heading-1;
        text-align: left;
        margin-bottom: var(--spacing-xxl)
    }
    &__description-heading {
        margin-top: var(--spacing-xxl);
    }
    &__description {
        color:var(--text-body-text);
        @include body;
    }
    &__transcript-link-wrapper {
        margin-top:var(--spacing-md);
        text-align: right;
    }
    &__video {

        width: calc(60vh * 1.77);
        margin-left:auto;
        margin-right:auto;
        @media (max-width:1200px) {
            width:calc(100vw - 30px);
        }
        max-width: 100%;

    }
    &__tag {
        @include eyebrow;
        display: block;
        margin-bottom:var(--spacing-xs);

        display: inline-block;
        margin-bottom: var(--spacing-xs);
       // vertical-align: text-bottom; This is causing an error-lm

    }
    &__video-inner {
        position: relative;
        width:100%;
        height: 60vh;
        @media (max-width:1200px) {
            height:calc((100vw - 30px) / 1.77 );
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // max-height: 70vh;
        }
    }
    &--auto-height {
        .o-featured-video__video-inner {
            height:auto;
        }
    }
}