svg.logo-svg{
    fill:white;
    height:32px;
}

.navbar-brand
{
    width:80px;
}

.scrolled svg.logo-svg{
    fill:white;
    height:24px !important;
}



.bg-light svg.logo-svg{
    transition: 0.2s all linear;
    fill:$dark-gray;
    height:32px;
}

.bg-transparent
{
    background-color:transparent;
    .nav-link{
        color:white !important;
    }
}

.bg-transparent-dark
{
    background-color:transparent;
    .nav-link{
        color:$dark-gray !important;
    }
}



.navbar-search svg.logo-svg{
    fill:white !important;
}

.bg-transparent svg.logo-svg{
    transition: 0.2s all linear;
    fill:white;
    height:32px;
}

.bg-transparent-dark svg.logo-svg{
    transition: 0.2s all linear;
    fill:$dark-gray;
    height:32px;
}

.scrolled, .no-scroll .navbar
{
    background-color:#f8f9fa !important;
    .nav-link
    {
        color:$dark-gray !important;
    }
    svg.logo-svg
    {
        fill:$dark-gray !important;
    }
}
.navbar-nav
{
    padding-top:.75rem;
    padding-bottom:.75rem;
    @media (max-width: 993px) { 
        margin-top: 3rem;
    }
    transition: all .3s ease-out;
    @media (max-width: 992px) {
        height: 80vh;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        min-height: 110%;
    }
}

.scrolled .navbar-nav 
{
    padding-top:0.25rem;
    padding-bottom:0.25rem;
}

.navbar {
    transition: all .3s ease-out;
    .nav-item{
        margin-left:1.1rem;
        margin-right:1.1rem;
        font-weight:400;
        font-size:16px;
        @media (min-width: 1300px) { 
            margin-left:1.5rem;
            margin-right:1.5rem;
        }
        @media (max-width: 993px) { 
            margin-left:0;
            margin-right:0;
            margin-bottom:1rem;
        }
        .nav-link
        {
            color:$dark-gray;
        }
    }
    .nav-item:first-of-type
    {
        margin-left:0;
    }
}

.nav-search-form
{
    position: relative;
}

.nav-search-box
{
    background-color:$light-gray;
    border:none;
    border-radius:100px;
    width:125px !important;
    transition: all .3s ease-out;
    @media (min-width: 1300px) { 
        width:200px !important;
    }
}

.bg-transparent .nav-search-box, .bg-transparent-dark .nav-search-box
{
    background-color:white;
    opacity:0.9;
}

.scrolled .nav-search-box{
    background-color:$light-gray;
}

.nav-search-box:focus
{
    width:250px !important;
    @media (min-width: 1300px) { 
        width:400px !important;
    }
}

.nav-search-btn
{
    position: absolute;
    right:20px;
    background-image: none;
    border:none;
    background-color:transparent;
}

.nav-search-btn-img
{
    height:20px !important;
    width:20px !important;
}

.navbar-search
{
    background-color:$dark-gray !important;
    transition: all .3s ease-out;
    .nav-search-box
    {
        background-color:white !important;
    }

}

.dropdown-menu
{
    background-color:$light-gray;
    border-radius:0;
    border:none;
    margin-top:0;
    .dropdown-item
    {
        margin-bottom:.5rem;
    }
}

.nav-item.show
{
    background-color:$light-gray;
    .nav-link
    {
        color:$dark-gray !important;
    }
}


