.o-card-grid {
    $spacing: 10px;
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: flex-start;
    &--collapsed {
        .o-card-grid__card:nth-child(n+7) {
            display: none !important;
        }
    }
    &__card {
        flex-basis:calc(50% - 16px);
        @media (max-width:767px) {
            flex-basis:calc(100% - 16px)
        }
        margin-top:10px;
        margin-bottom:10px;
        transition: all .3s ease-out;
        justify-content: flex-start;
        margin-left:7.5px;
        margin-right:7.5px;
        &--one-fourth {
            flex-basis:calc(25% - 15px);
            @media (max-width:992px) {
                flex-basis:calc(33.33% - 15px)
            }
            @media (max-width:767px) {
                flex-basis:calc(50% - 15px)
            }
        }
        &--one-third {
            flex-basis:calc(33.33% - 15px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 15px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 15px)
            }
        }
        &--two-third {
            flex-basis:calc(66.66% - 10px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 10px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 10px)
            }
        }
        &--fixed-medium {
            flex-basis:370px;
            max-width:370px;
        }
        &--full-width {
            flex-basis:100%;
            // padding-right:25%;
        }
    }
}

.o-card-grid--3-across.o-card-grid--collapsed {
    .o-card-grid__card:nth-child(n+4) {
        display: none !important;
    }
}
.o-card-grid--4-across.o-card-grid--collapsed {
    .o-card-grid__card:nth-child(n+5) {
        display: none !important;
    }
}
.o-card-grid--2-across.o-card-grid--collapsed {
    .o-card-grid__card:nth-child(n+3) {
        display: none !important;
    }
}