.m-notification-bar {
    height:auto;
    background-color: var(--surface-high-contrast-accent);

    position: relative;
    width:100%;
    padding:var(--spacing-sm) var(--spacing-xxl);
    color:var(--text-accent-complement);
    font-size:var(--font-sm-size);
    &__inner {
        color:var(--text-accent-complement);
        display: flex;
        align-items: center;
        justify-content:space-between;
        flex-direction: row;
        @media (max-width: 600px) {
            justify-content:center;
        }
    }
    &__content{
        @media (max-width: 1400px) {
            flex-basis:75%
        }
        @media (max-width: 992px) {
            flex-basis: 50%;
        }
        @media (max-width: 600px) {
            flex-basis: 100%;
            text-align: center;
        }
    }
    &__title {
        @include body--bold;
        @media (max-width: 1500px) {
            display: block;
        }
    }

    &__text {
        opacity: 0.8;
        @media (max-width: 1400px) {
            display: block;
        }
        @media (max-width: 992px) {
            display: none;
        }
    }
    &__cta {
        @include hyperlink;
        @media (max-width: 600px) {
            display: none;
        }
    }

}

.m-notification-bar:hover .m-notification-bar__inner {
    color:var(--text-accent-complement);
    text-decoration: underline;
}