﻿// CSS Variables

:root {

    /* Spacing variables */
    --font-size-ratio: calc(16 / 16); /* Calculate the ratio once */
    --spacing-factor: 1;

    --spacing-xxs: calc(0.25rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 4px */
    --spacing-xs: calc(0.5rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 8px */
    --spacing-sm: calc(0.75rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 12px */
    --spacing-md: calc(1rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 16px */
    --spacing-lg: calc(1.25rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 20px */
    --spacing-xl: calc(1.5rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 24px */
    --spacing-xxl: calc(2rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 32px */
    --spacing-3xl: calc(4rem * var(--font-size-ratio) * var(--spacing-factor)); /* Yields approximately 64px */

    --navbar-contact-right-margin: 130px;

    // Typography
    --font-base-size: 15px; /* This is your single source of truth for the font size */
    --font-xs-size: .75rem; /* Yields approximately 11.25px */
    --font-sm-size: .875rem; /* Yields approximately 13.5px */
    --font-md-size: 1rem; /* Yields approximately 15px */
    --font-lg-size: 1.25rem; /* Yields approximately 18.75px */
    --font-xl-size: 1.5rem; /* Yields approximately 18.75px */
    --font-xxl-size: 1.75rem; /* Yields approximately 22.5px */
    --font-3xl-size: 2.5rem; /* Yields approximately 30px */
    --font-default-weight: 400;
    --font-bold-weight: 700; //TODO: Replace all $font-heavy instances with this variable
    --heading-fonts: 'Public Sans', sans-serif;
    --body-fonts: 'Public Sans', sans-serif;
    --accent-fonts: 'Caveat', sans-serif;
    --line-height-default: 1.5;
    --line-height-extra-condensed: 1;
    --line-height-condensed: 1.1;
    --line-height-spacious: 2.1rem;

    // Color Primitives

    // Royal Azure
    --royal-azure-1: hsl(204.13, 100%, 10%);
    --royal-azure-2: hsl(204.13, 100%, 20%);
    --royal-azure-3: hsl(204.13, 100%, 27%);
    --royal-azure-4: hsl(204.13, 100%, 34%);
    --royal-azure-5: hsl(204.13, 100%, 37.06%); /* Brand Royal Azure */
    --royal-azure-5-opacity-10: hsla(204.13, 100%, 37.06%, .1);
    --royal-azure-6: hsl(204.13, 100%, 44%);
    --royal-azure-7: hsl(204.13, 100%, 51%);
    --royal-azure-8: hsl(204.13, 100%, 65%);
    --royal-azure-9: hsl(204.13, 100%, 80%);
    --royal-azure-10: hsl(204.13, 100%, 95%);

    // Deep Sea Blue
    --deep-sea-blue-1: hsl(184.58, 100%, 5%);
    --deep-sea-blue-2: hsl(184.58, 100%, 10%);
    --deep-sea-blue-3: hsl(184.58, 100%, 15%);
    --deep-sea-blue-4: hsl(184.58, 100%, 20%);
    --deep-sea-blue-5: hsl(184.58, 100%, 25.69%); /* Brand Deep Sea Blue */
    --deep-sea-blue-5-opacity-10: hsla(184.58, 100%, 25.69%, .1);
    --deep-sea-blue-6: hsl(184.58, 100%, 35%); /*Alternative to 67AFB5*/
    --deep-sea-blue-7: hsl(184.58, 100%, 45%);
    --deep-sea-blue-8: hsl(184.58, 100%, 55%);
    --deep-sea-blue-9: hsl(184.58, 100%, 65%);
    --deep-sea-blue-10: hsl(184.58, 100%, 75%);

     /* Rich Shamrock gradient */
    --rich-shamrock-1: hsl(150.48, 90%, 5%);
    --rich-shamrock-2: hsl(150.48, 90%, 10%);
    --rich-shamrock-3: hsl(150.48, 90%, 15%);
    --rich-shamrock-4: hsl(150.48, 90%, 20%);
    --rich-shamrock-5: hsl(150.48, 90%, 27.45%); /* Your exact Rich Shamrock */
    --rich-shamrock-6: hsl(150.48, 90%, 35%);
    --rich-shamrock-7: hsl(150.48, 90%, 45%);
    --rich-shamrock-8: hsl(150.48, 90%, 55%);
    --rich-shamrock-9: hsl(150.48, 90%, 65%);
    --rich-shamrock-10: hsl(150.48, 90%, 75%);

    /* Vivid Vermilion gradient */
    --vivid-vermilion-1: hsl(2.84, 76.47%, 15%);
    --vivid-vermilion-2: hsl(2.84, 76.47%, 25%);
    --vivid-vermilion-3: hsl(2.84, 76.47%, 35%);
    --vivid-vermilion-4: hsl(2.84, 76.47%, 45%);
    --vivid-vermilion-5: hsl(2.84, 76.47%, 56.67%); /* Your exact Vivid Vermilion */
    --vivid-vermilion-6: hsl(2.84, 76.47%, 60%);
    --vivid-vermilion-7: hsl(2.84, 76.47%, 65%);
    --vivid-vermilion-8: hsl(2.84, 76.47%, 70%);
    --vivid-vermilion-9: hsl(2.84, 76.47%, 75%);
    --vivid-vermilion-10: hsl(2.84, 76.47%, 80%);

    // Serene Cyan
    --serene-cyan-1: hsl(197.33, 70%, 10%);
    --serene-cyan-2: hsl(197.33, 70%, 20%);
    --serene-cyan-3: hsl(197.33, 70%, 30%);
    --serene-cyan-4: hsl(197.33, 70%, 40%);
    --serene-cyan-5: hsl(197.33, 70%, 50%);
    --serene-cyan-6: hsl(197.33, 70%, 60%);
    --serene-cyan-7: hsl(197.33, 70%, 70%);
    --serene-cyan-8: hsl(197.33, 70%, 75%); /* Your exact Serene Cyan */
    --serene-cyan-9: hsl(197.33, 70%, 85%);
    --serene-cyan-10: hsl(197.33, 70%, 95%);

    /* Pumpkin Orange gradient */
    --pumpkin-orange-1: hsl(31.79, 100%, 10%);
    --pumpkin-orange-2: hsl(31.79, 100%, 20%);
    --pumpkin-orange-3: hsl(31.79, 100%, 30%);
    --pumpkin-orange-4: hsl(31.79, 100%, 40%);
    --pumpkin-orange-5: hsl(31.79, 100%, 49.22%); /* Your exact Pumpkin Orange */
    --pumpkin-orange-6: hsl(31.79, 100%, 59%);
    --pumpkin-orange-7: hsl(31.79, 100%, 69%);
    --pumpkin-orange-8: hsl(31.79, 100%, 79%);
    --pumpkin-orange-9: hsl(31.79, 100%, 89%);
    --pumpkin-orange-10: hsl(31.79, 100%, 99%);

    /* Blues */
    --light-sky-blue: hsl(193.85, 70.91%, 78.43%); /* #A1DDEF */
    --deep-sea-blue: hsl(184.58, 100%, 25.69%); /* #007983 */
    --dark-sapphire: hsl(212, 67.16%, 26.27%); /* #164070 */
    --sky-blue: hsl(192.17, 100%, 40.59%); /* #00A5CF */
    --soft-periwinkle: hsl(230, 93.51%, 69.8%); /* #6A82FA */
    --ocean-blue: hsl(203.92, 100%, 28.04%); /* #00568F */
    --lavender-blue: hsl(230, 93.51%, 69.8%); /* #6A82FA */
    --bright-blue: hsl(198.18, 100%, 60.59%); /* #009DDC */
    --lightest-blue: hsl(189, 37%, 96%); /* #F2F8F9 */
    --dark-blue: hsl(210, 100%, 18%); // #002a5d

    /* Grays */
    --white: hsla(0, 0%, 100%, 1); /* #FFFFFF */
    --white-90-opacity: hsla(0, 0%, 100%, .9); /* White - 90% opacity */
    --white-5-opacity: hsla(0, 0%, 100%, .05); /* White - 5% opacity */
    --neutral-100: hsl(0, 0%, 96.47%); /* #F5F5F5, E6F2F3, legacy $lightest-gray */
    --neutral-300: hsl(0, 0%, 87.06%); /* #DEDEDE, legacy $light-gray */
    --neutral-400: hsl(210, 16%, 76%); /* rgba(184, 194, 204, 1) */
    --neutral-500: hsl(0, 0%, 59.61%); /* #989898 light-medium-gray, a5a5a5*/
    --neutral-700: hsl(0, 0%, 43.92%); /* #707070, legacy $medium-gray */
    --neutral-800: hsl(0, 0%, 23.92%); /* #3D3D3D, legacy $dark-gray */
    --neutral-850: hsl(0, 0%, 14.0%); /* #242424 */
    --neutral-900: hsla(0, 0%, 6.67%, 1); /* #111111 */
    --black: hsla(0, 0%, 0%, 1); /* #000000 */
    --black-90-opacity: hsla(0, 0%, 0%, .9); /* #000000 */
    --black-5-opacity: hsla(0, 0%, 0%, .05); /* #000000 */
    --black-80-opacity: hsla(0, 0%, 0%, .8); /* #000000 */

    /* Reds, Oranges, Yellows, Greens, Purples */
    --vivid-vermilion: hsl(2.84, 76.47%, 56.67%); /* #E5443C */
    --vivid-vermilion-darken-10: hsl(2.84, 76.47%, 46.67%); /* #E5443C #A21900 */
    --vivid-vermilion-darken-20: hsl(9, 100%, 32%); /* #A21900, For Warning */
    --bright-sunflower: hsl(42.86, 100%, 50.59%); /* #FFB703, FBAE30 */
    --pumpkin-orange: hsl(31.79, 100%, 49.22%); /* #FB8500 , F0705F*/
    --rich-shamrock: hsl(150.48, 90%, 27.45%); /* #078547 */
    --rich-shamrock-light: hsl(148, 57%, 43%); /* #2FAD69 */
    --apple-green: hsl(96.39, 53.04%, 54.9%); /* #7FC94F */
    --purple: hsl(270, 100%, 42%); /*6C00D6 */


    --color-accent-color: #007983;
    --color-light-accent-color: #bfe6f6; // legacy $icon-color
    --color-light-accent-alt: #A1DDEF;
    --color-extra-light-accent-color: #DCF5FF;
    --color-dark-accent-color: var(--dark-sapphire); //legacy $background-blue;
    --color-action-color: #0071BC;

    // Academy Colors
    --color-dark-blue: hsl(210, 100%, 19%); /* #002A5D */
    --color-blue: #0C65D1; /* #0C65D1 */
    --color-medium-blue: #1D4691; /* #1D4691 */
    --color-light-blue: hsl(210, 86%, 84%); /* #B2E4F9 */
    --color-very-light-blue: hsl(204, 73%, 96%); /* #EFF6FB */

    // Sizing Variables
    --sizing-navbar-logo: 28px;
    --sizing-footer-logo: 28px;

    /*

     Semantic Colors

    */

    --color-body-bg: var(--white);

    // Action Colors
    --color-action-color: var(--royal-azure-5);
    --color-action-color-faded: var(--royal-azure-5-opacity-10);
    --color-action-color-hover: var(--royal-azure-3);
    --color-action-color-complement: var(--white);

    // Disabled Colors
    --color-disabled-button-bg: var(--neutral-100);
    --color-disabled-button-bg-hover: var(--neutral-300);
    --color-disabled-button-text: var(--neutral-700);

    // Text Colors
    --text-heading-text: var(--neutral-900);
    --text-body-text: var(--neutral-900);
    --text-body-text-muted: var(--neutral-800); // Have used $medium-gray historically
    --text-accent: var(--deep-sea-blue-5);
    --text-accent-complement: var(--white);
    --accent-color: #007983; // Legacy Variable in use in Data Engagement Reporting. Keep for now.
    --text-destructive: var(--vivid-vermilion-4);
    --text-destructive-hover: var(--vivid-vermilion-3);
    --text-destructive-complement: var(--white);
    --text-body-text-inverse: var(--white);

    //TODO: convert all instances of color-destructive to color-error

    // Status Colors
    --color-success: var(--rich-shamrock-4);
    --color-success-complement: var(--white);
    --color-error: var(--vivid-vermilion-4);
    --color-error-complement: var(--white);
    --color-error-hover: var(--vivid-vermilion-3);
    --color-neutral: var(--neutral-700);
    --color-neutral-complement: var(--white);
    --color-informational: var(--serene-cyan-6);
    --color-informational-complement: var(--neutral-900);
    --color-warning: var(--pumpkin-orange-5);

    //Accessibility Colors
    --color-focus: var(--soft-periwinkle);

    //  Surfaces
    --surface-body-bg: var(--white);
    --surface-low-contrast: var(--neutral-100);
    --surface-low-contrast-accent: var(--deep-sea-blue-5-opacity-10);
    --surface-low-contrast-accent-complement: var(--deep-sea-blue-3);
    --surface-high-contrast: var(--dark-sapphire);
    --surface-high-contrast-hover: var(--dark-blue);
    --surface-medium-contrast: var(--dark-sapphire);
    --surface-high-contrast-complement: var(--white);
    --surface-high-contrast-accent: var(--accent-color);
    --surface-high-contrast-success: var(--rich-shamrock-6);
    --surface-high-contrast-error: var(--vivid-vermilion-6);
    --surface-high-contrast-glass: var(--black-80-opacity);
    --surface-high-contrast-warning: var(--pumpkin-orange-5);

    // Lines & Borders
    --line-low-contrast:var(--neutral-300);

    // Border Radius
    --border-radius-xs: 2px;
    --border-radius-sm: 6px;
    --border-radius-md: 8px;
    --border-radius-lg: 10px;
    --border-radius-xl: 12px;
    --border-radius-xxl: 16px;
    --border-radius-xxxl: 32px; /*2.0rem*/
    --border-radius-circle: 500px;

    --border-radius-btn-default: 0;
    --border-radius-img-default: 0;

    // Border Width
    --border-width-xs: 0 2px 2px 0;

    // Shadows
    --shadow-light: 0px 0px 5px 2px rgba(0,0,0,0.1);
    --shadow-medium: 0px 2px 38px -9px rgba(0,0,0,0.35);

    // Footer
    --footer-grid-template-rows: 75px 1fr 75px 100px 100px;

    // Overlays
    --overlay-image-gradient: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 51%,transparent 100%);
    --overlay-image-gradient-hover: linear-gradient(30deg,rgba(0,0,0,.934331) 0,rgba(0,0,0,.667945) 61%,transparent 100%);
}

[data-theme="academy"] {
    --accent-color: var(--color-medium-blue);
    --text-accent: var(--color-medium-blue);
    --color-accent: var(--color-blue);
    --surface-low-contrast-accent: var(--color-very-light-blue);
    --surface-high-contrast-accent: var(--color-blue);
    --color-action-color: var(--color-blue);
    --color-dark-accent-color: var(--color-medium-blue);
    --surface-medium-contrast-accent: var(--color-medium-blue);
    --border-radius-btn-default: 500px;
    --border-radius-img-default: var(--border-radius-xxl);
    --surface-high-contrast: var(--color-medium-blue);
    --sizing-navbar-logo: 40px;
    --sizing-footer-logo: 64px;
    --footer-grid-template-rows: 75px 1fr 0px 0px 100px;
    --navbar-contact-right-margin: 75px;
    --overlay-image-gradient: linear-gradient(30deg,rgba(0,0,0,0) 0,rgba(0,0,0,.0) 51%,transparent 100%);
    --overlay-image-gradient-hover: linear-gradient(30deg,rgba(0,0,0,0) 0,rgba(0,0,0,0) 61%,transparent 100%);
    // --color-action-color: var(--royal-azure-7);
    // --color-action-color-hover: var(--royal-azure-8);
    // --color-action-color-complement: var(--neutral-900);
    // --color-disabled-button-bg: var(--neutral-100);
    // --color-disabled-button-bg-hover: var(--neutral-300);
    // --color-disabled-button-text: var(--neutral-700);
    // --color-body-text: var(--white);
    // --color-body-text-muted: var(--neutral-300);
    // --color-accent: var(--deep-sea-blue-6);
    // --color-accent-complement: var(--neutral-900);
    // --color-heading-text: var(--neutral-900);
    // --color-focus: var(--soft-periwinkle);
    // --color-destructive: var(--vivid-vermilion);
    // --color-destructive-hover: var(--vivid-vermilion-darken-10);
    // --color-body-bg: var(--neutral-850); // Get Rid Of This. Superceded by --surface-body-bg

    // // Status Colors
    // --color-success: var(--rich-shamrock-6);
    // --color-success-complement: var(--neutral-900);
    // --color-error: var(--vivid-vermilion-6);
    // --color-error-complement: var(--neutral-900);
    // --color-error-hover: var(--vivid-vermilion-darken-3);
    // --color-warning: var(--pumpkin-orange);
    // --color-neutral: var(--neutral-500);
    // --color-neutral-complement: var(--neutral-900);

    // // Surfaces
    // --surface-body-bg: var(--neutral-850);
    // --surface-low-contrast: var(--white-5-opacity);
    // --surface-low-contrast-accent: var(--white-5-opacity);

    // // Lines
    // --line-low-contrast:var(--neutral-700);
    // .o-footer__button {
    //     background-color: var(--color-light-accent-color) ;
    //     border-color: var(--color-light-accent-color);
    //     color: var(--dark-sapphire) !important;
    //     :hover {
    //         background-color:white !important;
    //         color: var(--dark-sapphire) !important;
    //         border-color: var(--color-light-accent-color) !important;
    //         color: var(--dark-sapphire) !important;
    //     }
    // }
}



// Legacy SCSS Variables
$high-contrast: false !default;
$font-stack: "Public Sans", sans-serif;
$body-font: "Public Sans", sans-serif;
$header-font: 'Public Sans', serif;

$font-heavy:700;

$primary-color: orange;
$secondary-color:#DB3535;
$bg-color: #ffffff;
$beige: #FBF6F6;

$lightest-gray: #f5f5f5;
$light-gray: #dedede;
$light-medium-gray: #a5a5a5;
$medium-gray: #707070;
$dark-gray: #3D3D3D;
$purple: #29225c;
$medium-purple:#4e41b0;
$warning: #A21900;
$yellow: #FFB703;
$orange: #FB8500;
$periwinkle: #6A82FA;
$highlight: rgba(255, 184, 3, 0.5);

$lightest-blue: #F2F8F9;

$dumb: #2d2d2d;

$dark-blue: #002a5d;
$bright-blue: #009ddc;
$medium-blue: #0079A8;
$teal: #5ec2b7;
$light-blue:#bfe6f6;
$background-blue: #164070;

$eyebrow: #007983;
$icon-color: #A1DDEF;

// $action-color:#f15b3a;
// $action-color: #DB3535;
$action-color: #0071BC;

@if $high-contrast == true {
    $action-color:#4e41b0;
}

$green: #17bebb;

$hero-opacity: 0.8;

$wide-breakpoint-min: 1260px;
$medium-breakpoint-max: 1259px;

$wideMenuHeight: 80px;

$nav-breakpoint: 1200px;

$gutter: 10px;

$tag-font-size: 1rem;
$bold: 700;