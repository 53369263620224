.m-page-turner {
    display: flex;
    justify-content: center;
    width:100%;
    align-items: center;
    @media (max-width: 767px) {
        flex-wrap: wrap;
        align-items: stretch;
     }
    &__link {
        @extend .a-button;
        @extend .a-button--outline;
        flex-basis: 20ch;
        @media (max-width: 767px) {
            order:1;
            flex-basis:calc(50% - 8px);
            display: flex;
            flex-direction: column;
            justify-content: center;
         }
        &--previous {
            margin-right:auto;
            @media (max-width: 767px) {
                order:1;
                flex-basis:calc(50% - 8px);
             }

        }
        &--previous::before {
            @media (min-width:768px) {
                content:'→';
                margin-right:var(--spacing-xxs);
                display: inline-block;
                transform: rotate(180deg);
            }
        }
        &--next {
            margin-left:auto;
            @media (max-width: 767px) {
                order:2;
             }
        }
        &--next::after {
            @media (min-width:768px) {
                content:'→';
                display: inline-block;
                margin-left:var(--spacing-xxs);
            }
        }
    }
    &__back-link {
        @media (max-width: 767px) {
            order:3;
            margin-top: var(--spacing-md);
            flex-basis:100%;
         }
    }
    &__link:hover {
        text-decoration: underline;
    }


}