.m-featured-cta-card {
    background-color:var(--lightest-blue);
    border-radius:var(--border-radius-lg);
    padding:var(--spacing-xxl) var(--spacing-xl);
    &__tag {
        color:var(--bright-blue);
        padding-bottom:var(--spacing-xs);
        display: inline-block;
    }
    &__title {
        font-size:var(--font-xl-size);
        font-family: var(--body-fonts);
    }
    &__text {

    }
    &__btn {
        background-color: var(--bright-blue);
        color:var(--white);
        padding:var(--spacing-sm) var(--spacing-xl);
        margin-top:var(--spacing-xs);
        display: inline-block;
        border-radius: var(--border-radius-sm);
    }
}