.o-event-slider {
    // This allows for dropshadows on cards when using SwiperJS
    padding-top:1rem !important;
    padding-bottom:1rem !important;
    &__prev {
        color: rgba(0,0,0,0.8) !important;
    }
    &__next {
        color: rgba(0,0,0,0.8) !important;
    }
}