.a-icon-button {
    text-align: center;
    padding:.5rem 1rem;
    //border-radius:6px;
    transition: all .2s ease-in;
    display: inline-block;
    // background-color:rgba(0,0,0,0.05);
    &--large {
        padding: 1rem 1.5rem;
    }
    &__img {
        //display: inline-block;
        fill: $light-gray;
        stroke: pink;
        width:32px;
        height:32px;
        &--large {
            width:48px;
            height:48px;
            fill: $light-gray;
            stroke: pink;
        }
        // margin-bottom:8px;
    }
    &__label {
        font-size:0.9rem;
        display: block;
        color:$medium-gray;
        font-weight:400;
        line-height: 1.2;
        transition: all .2s ease-in;
    }
}
.a-icon-button:hover {
    background-color:rgba(0,0,0,0.05);
}
.a-icon-button:hover .a-icon-button__label {
    color:$dark-gray;
    @include hyperlink;
    font-size:0.9rem;
}

