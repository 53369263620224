.o-news-collection {
    display:flex;
    flex-direction: row;
    @media (max-width:992px) {
        flex-wrap: wrap;
    }
    @media (max-width:767px) {
        flex-direction: column;
    }
    &__left-col {
        flex-basis: calc(25% - 7.5px);
        // margin-left:7.5px;
        margin-right: 7.5px;
        
        @media (max-width:992px) {
            order:3;
            flex-basis: 100%;
            flex-direction: row;
            margin-top:1rem;
        }
        @media (max-width:767px) {
            order:2;
            margin-bottom: 5rem;
        }
    }
    &__left-col-inner {
        display: flex;
        flex-direction: column;
        @media (max-width:992px) {
            flex-direction: row;
            justify-content: space-between;
        }
        @media (max-width:767px) {
            flex-direction: column;
            justify-content: flex-start;
            // align-items: center;
        }
    }
    &__highlight {
        @media (max-width:992px) {
            margin-right:15px;
        }
        @media (max-width:767px) {
            margin-right:0px;
            margin-bottom:1rem;
        }
    }
    &__highlight:last-of-type{
        @media (max-width:992px) {
            margin-right:0;
        }
    }
    &__center-col {
        flex-basis: calc(50% - 15px);
        margin-left:7.5px;
        margin-right: 7.5px;
        @media (max-width:992px) {
            order:1;
            flex-basis: calc(66% - 15px);
        }
        @media (max-width:767px) {
            order:1;
            flex-basis:auto;
        }
    }
    &__right-col {
        flex-basis: calc(25% - 7.5px);
        margin-left:7.5px;
        @media (max-width:992px) {
            order:2;
            flex-basis: calc(33% - 7.5px);
        }
        @media (max-width:767px) {
            order:3;
        }
        // border-left: solid 1px $light-gray;
        // margin-right: 7.5px;
    }
}