.o-top-menu {
    height:45px;
    background-color: var(--color-dark-accent-color);
    display: flex;
    position: relative;
    top:0px;
    left:0px;
    width:100%;
    padding: var(--spacing-xxs) var(--spacing-md) var(--spacing-xxs) 0;
    align-items: center;
    flex-direction: row;
    // margin-bottom:70px;
    &__skip-content-link {
        background-color: var(--neutral-100);
        height:45px;
        line-height: 45px;
        padding: 0 var(--spacing-md);
        transform: translateX(-100%);
        transition: transform 0.3s ease-out;
    }
    &__skip-content-link:focus-visible {
        outline-style: solid;
        outline-color: var(--soft-periwinkle);
        outline-width: 3px;
        outline-offset: -3px;
        text-decoration: underline;
        transform:  translateX(0%);
    }
    &__links {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        list-style-type: none;
        list-style-image: url('');
        align-items: center;
        margin-bottom: 0;
        border-right:none;
        // margin-right:1.25rem;
    }
    &__link-item {
        padding:var(--spacing-xxs) var(--spacing-lg);
        margin:0 0 0 0;
        line-height: var(--line-height-condensed);
        border-right:solid 1px rgba(255,255,255,0.2);
        opacity: 0.85;
        &--icon {
            border-right:none;
            padding:var(--spacing-xxs) var(--spacing-xs);
            opacity: 0.7;
        }
        // &--icon:hover {
        //     transition: all 0.2s ease-out;
        //     opacity: 1;
        // }
        &--icon:first-of-type {
            padding-left:var(--spacing-lg) !important;
        }
    }
    &__link-item:hover {
        transition: all 0.2s ease-out;
        opacity: 1;
        text-decoration: underline;
    }
    &__link-item:last-of-type {
        border-right:none;
    }
    &__icon {
        height:24px !important;
        width:auto;
    }
    &__link {
        color:var(--white);
        outline-offset: 4px;
        outline-style: solid;
        outline-width: 3px;
        outline-color: transparent;
        &--bold {
            font-weight:var(--font-bold-weight);
        }
    }
    &__link:focus-visible {
        @include focused-offset;
    }
    &__link:hover {
        color:white;
        text-decoration: underline;
    }
    &__logo {
        height:32px !important;
        width: auto;
        margin-left:24px;
    }
}