.o-asset-bundle {
    background-color: $lightest-gray;
    padding: 1rem;
    border-radius:0px;
    &__title {
        font-size: 1.5rem !important;
        font-family: $body-font !important;
        font-weight:$bold !important;
        margin-top:0.25rem !important;
    }
    &__description {
        font-family: $body-font;
        max-width: 600px;
        font-size:1rem;
    }
}