.m-highlight {
  width: 100%;
  // max-width: 50.625rem; // 810px
  // min-height: 25rem; // 400px
  background-color: var(--surface-body-bg);
  // margin: 0 5px 20px;
  padding: 0px;
  display: flex;
  height:100%;
  &:hover {
    background: var(--surface-body-bg);
    box-shadow: var(--shadow-light);
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex: 3.5;
    flex-basis:45%;
    // gap: var(--spacing-3xl);
    padding: var(--spacing-xxl) var(--spacing-lg);
  }

  &__title {
    // font-size: var(--font-3xl-size);
    // font-weight: var(--font-default-weight);
    // line-height: 1.2;
    margin-bottom: var(--spacing-md);
  }

  &__text {
    font-size: var(--font-base-size);
    line-height: var(--line-height-default);
    margin-bottom: var(--spacing-lg);
  }

  &__link {
    font-size: var(--font-base-size);
    color: var(--color-action-color);
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
      color:var(--color-action-color-hover);
    }
  }

  &__image-container {
    // flex: 6.5;
    flex-basis:55%;
    width: 100%;
    min-height: 350px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }

  // Vertical variant
  &--vertical {
    flex-direction: column;

    .m-highlight__image-container {
      flex-basis: 150px;
      width: 100%;
      min-height: 200px;
    }
    .m-highlight__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 18 / 5; // Corrected aspect ratio for vertical variant
    }

    .m-highlight__content {
      // flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: var(--spacing-xxl) var(--spacing-lg);
    }

    .m-highlight__links {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
  &--accent {
    background-color: var(--color-accent-color);
    color: var(--text-accent-complement);
    &:hover {
      background-color: var(--color-accent-color);
    }
    .m-highlight__link {
      color: var(--text-accent-complement);
    }
  }
  &--accent-dark {
    background-color: var(--color-dark-accent-color);
    color: var(--text-accent-complement);
    &:hover {
      background-color: var(--color-dark-accent-color);
    }
    .m-highlight__link {
      color: var(--text-accent-complement);
    }
  }
  &--accent-light {
    background-color: var(--color-extra-light-accent-color);
    &:hover {
      background-color: var(--color-extra-light-accent-color);
    }
  }
}


// Media queries
@media (max-width: 768px) {
  .m-highlight {
    flex-direction: column;

    &__content,
    &__image-container {
      width: 100%;
    }

    &__content {
      // flex: 4;
      padding: 1.5rem;
      // gap: 2rem;
    }

    &__image-container {
      flex: 1;
      order: -1;
      height: 300px;
    }
    &__image {
      aspect-ratio: 4 / 3 !important;
    }
  }
}

@media (max-width: 480px) {
  .m-highlight {
    &__title {
      font-size: 1.5rem;
    }

    &__content {
      padding: 1rem;
      // gap: 1.5rem;
    }

    &__image-container {
      min-height: 150px;
    }
  }
}