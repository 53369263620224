.m-outline {
 &__items {
    padding-left:0;
    list-style: none;
    margin-top:var(--spacing-md);
 }
 &__item {
    margin-bottom:var(--spacing-sm);
    border-left: 5px solid var(--line-low-contrast);
    padding-left:var(--spacing-xs);
    // padding-top:.25rem;
    // padding-bottom:.25rem;
    transition: all 0.3s ease-out;
    &:hover {
        border-left: 5px solid var(--color-focus);
        .m-outline__link {
            text-decoration: underline;
        }
    }
 }

 &__link {
    text-decoration: underline;
    padding:var(--spacing-xxs) 0;
    // min-height:32px;
    display: inline-block;
    @media (max-width: 767px) {
        padding:var(--spacing-xs) 0;
    }
 }
}
