﻿@mixin transition-on-color
{
    transition: color 0.3s;
}

@mixin transition-all
{
    transition: all 0.4s;
}

@mixin shadowed
{
    -webkit-box-shadow: var(--shadow-medium);
    -moz-box-shadow: var(--shadow-medium);
    box-shadow: var(--shadow-medium);
}

@mixin focused-offset {
  outline-style: solid;
  outline-color: var(--soft-periwinkle);
  outline-width: 3px;
  outline-offset: 2px;
}
@mixin focused-offset-thin {
  outline-style: solid;
  outline-color: var(--soft-periwinkle);
  outline-width: 3px;
  outline-offset: 4px;
}

@mixin focused-no-offset {
  outline-style: solid;
  outline-color: var(--soft-periwinkle);
  outline-width: 3px;
  outline-offset: 2px;
}

@mixin focused-no-offset-thin {
  outline-style: solid;
  outline-color: var(--soft-periwinkle);
  outline-width: 3px;
  outline-offset: 0px;
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}



@mixin block-title--quote(){
  @include pull-quote;
}

@mixin body(){
  //font-size: 16px;
  font-size: 1rem;
  font-weight: var(--font-default-weight);
}

@mixin body--bold(){
  font-size: 1rem;
  font-weight: var(--font-bold-weight);
}

@mixin heading-1() {
  //font-size:52px;
  font-size: 3.25rem;
  font-weight:var(--font-bold-weight);
  @media (max-width: 993px) {
    font-size:32px;
  }
}

@mixin heading-2(){
  //font-size:36px;
  font-size:2.25rem;
  font-weight:var(--font-default-weight);
  @media (max-width: 993px) {
    font-size:1.75rem;
  }
}

@mixin heading-3(){
  //font-size:28px;
  font-size: 1.75rem;
  font-weight:var(--font-bold-weight);
  @media (max-width: 993px) {
    font-size:1.5rem;
  }
}

@mixin heading-4(){
  //font-size:24px;
  font-size: 1.5rem;
  font-weight:var(--font-bold-weight);
  @media (max-width: 993px) {
    font-size:1.25rem;
  }
}

@mixin heading-5(){
  //font-size:24px;
  //font-size:20px;
  font-size:1.25rem;
  font-weight:var(--font-bold-weight);
  @media (max-width: 993px) {
    font-size:1rem;
  }
}

@mixin heading-6(){
  @extend .heading, .a-heading;
  //font-size:16px;
  font-size: 1rem;
  font-weight:var(--font-bold-weight);
}

@mixin subhead(){
  //font-size: 20px;
  font-size: var(--font-lg-size);
  font-weight: var(--font-default-weight);
}

@mixin eyebrow(){
  //font-size:16px;
  font-size: 1rem;
  font-weight:var(--font-bold-weight);
  color:var(--text-accent);
  margin-bottom: 0;
}



@mixin eyebrow--light-blue {
    color:var(--color-light-accent-color);
    font-size: var(--font-md-size);
    font-weight: var(--font-bold-weight);
}

@mixin eyebrow--white {
  @include eyebrow();
    color:var(--text-body-text-inverse);
}

@mixin pull-quote() {
  color: var(--text-accent);
  //font-size: 20px;
  font-size:var(--font-lg-size);
}

//don't think we need body mixin

@mixin hyperlink(){
  //font-size: 16px;
  font-size: var(--font-md-size);
  &:hover {
    text-decoration: underline;
}
}





@mixin footer-link() {
  //font-size: 14px;
  font-size: var(--font-sm-size);
  font-weight: var(--font-default-weight);
}

