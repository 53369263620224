.m-chart-card {
    background-color:var(--neutral-100);
    padding:var(--spacing-md) var(--spacing-xxl) var(--spacing-xxl) var(--spacing-md);
    border-radius:var(--border-radius-lg);
    &__chart {
        margin-top:var(--spacing-xxl);
    }
    &__title {
        font-family: var(--heading-fonts);
        font-size: var(--font-xl-size);
        text-align: center;
        margin: var(--spacing-md) var(--spacing-md) var(--spacing-xxs) var(--spacing-md);
    }
    &__subtitle {
        font-size:var(--font-md-size);
        text-align: center;
        margin-top:0;
        margin-bottom: var(--spacing-xxl);
        color:var(--neutral-700);
    }
}

