.o-event-result
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding:1.5rem 0 1.5rem 1.5rem;
    flex-wrap: wrap;
    background-color: white;
    // border-radius:15px;
    // -webkit-box-shadow: 2px 2px 20px 1px rgba(0,0,0,0.10); 
    // box-shadow: 2px 2px 20px 1px rgba(0,0,0,0.10);
    border:solid 1px $light-gray;
    transition: all 0.3s ease-out;
    &--featured {
        background-color: $lightest-gray;
        border-radius: 6px;
        padding:1.5rem 1rem;
    }
    @media (max-width:993px)
    {
        flex-direction:column;
    }
    margin-bottom:1.5rem;
    &__featured-tag {
        flex-basis: 100%;
        color:$medium-gray;
        font-weight: 500;
        text-transform: uppercase;
    }
    &__title
    {
        font-size:1.5rem;
        color:$dark-gray;
        transition: all .2s ease-out;
        font-family: $header-font;
        font-weight:900;
        margin-top:0;
    }
    &__image-wrapper
    {
        flex-basis:25%;
    }
    &__image
    {
        width:100%;
        // height:128px;
        height: 100%;
        max-height:160px;
        object-fit: cover;
        // border-radius:6px;
    }

    &__details-wrapper
    {
        flex-basis:55%;
        color:$dark-gray;
    }
    
    &__cta-wrapper
    {
        flex-basis:15%;
        color:$dark-gray;
        
        border-left:solid 1px $lightest-gray;
    }
    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:100%;
    }
    &__excerpt {
        color:$dark-gray;
    }
    &__tag
    {
        color: $bright-blue;
        @if $high-contrast == true {
            color:$dark-gray !important;
        }
        padding: 0rem 0rem .25rem 0rem;
        display: inline-block;
        border-radius: 6px;
        font-weight: 300;
        font-size: 1rem;
        // margin-bottom: .25rem;
    }
    &__reg-img {
        width: 32px;
        height: 32px;
    }
}

.o-search-result:hover {
    -webkit-box-shadow: 2px 2px 25px 1px rgba(0,0,0,0.20); 
    box-shadow: 2px 2px 25px 1px rgba(0,0,0,0.20);
}
.o-search-result:hover .o-search-result__title
{
    color:$action-color;
}

.o-search-result:first-of-type
{
    margin-top:0;
}