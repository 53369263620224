.m-site-search-form
{
    position:relative;
    &__input
    {
        @include subhead;
        padding:var(--spacing-xs) var(--spacing-xxl);
        width:100%;
        border:solid 1px var(--line-low-contrast);
        transition: all .3s ease-out;
        outline-width: 3;
        outline-color: transparent;
        outline-style: solid;
        outline-offset: 0px;
        @media (max-width:993px)
        {
            font-size:var(--font-xl-size);
        }

    }
    &__input:focus-visible
    {
        @include focused-no-offset;
    }
    &__submit-btn
    {
        position: absolute;
        right:20px;
        top:9px;
        background-image: none;
        border:none;
        background-color:transparent;
    }
    &__submit-btn:focus-visible
    {
       @include focused-no-offset;
    }
    &__input::placeholder
    {
        color:var(--text-body-text);
        font-weight:var(--font-default-weight);
        font-size:var(--font-lg-size);
        @media (max-width:993px)
        {
            font-size:var(--font-md-size);
        }
    }
    &__submit-btn-img
    {
        height:24px !important;
        width:24px !important;
        @media (max-width:993px)
        {
            height:24px !important;
            width:24px !important;
        }
    }

}