.m-action-menu {
    background-color:rgba(255,255,255,0.4);
    padding:1rem;
    display: inline-block;
    border-radius: 6px;
    &__label {
        display: inline-block;
    }
    &__select {
        background: transparent;
        border:none;
        border-bottom: solid 1px $bright-blue;
        color: $bright-blue;
        font-weight:600;
        margin-right:0.5rem;
        // -webkit-appearance:none;
        border-radius: 0;
        &--red {
            color:$action-color;
            border-bottom: solid 1px $action-color;
        }
    }
}