.o-three-quarters-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    
    background-color:var(--neutral-100);
    padding:0;
    // margin-left:15px;
    // margin-right:15px;
   
   
    
    // padding-top:10rem;
    // padding-bottom:10rem;
    
    position: relative;
    
    @media (max-width: 1000px) { 
        padding-top:5rem;
        padding-bottom:5rem;
        // height:23rem;
     }
    &__wide-col {
        @extend .l-two-thirds-col;
        
        @media (min-width:993px) {
            min-height:40vh;
        }
        background-size:cover;
        background-position-x: right;
        background-position-y: center;
        background-color:transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        align-items: stretch;
        background-repeat:no-repeat;
        padding:var(--spacing-xxl);
        
    }
    &__narrow-col {
        @extend .l-one-thirds-col;
        padding:var(--spacing-xxl);
        
    }
    &__wide-inner {
        // margin-top:80px;
        display: flex;
        z-index: 1;
    }

    &__inner--no-nav {
        margin-top:0px;
        display: flex;
    }
    &__overlay {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: var(--black);
        background: linear-gradient(38deg, rgba(0,0,0,0.7343312324929971) 0%, rgba(0,0,0,0.5102415966386555) 32%, rgba(0,0,0,0) 100%);
        z-index: 0;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__img {
        border-radius:500px;
        max-width:130px;
        margin-bottom:var(--spacing-md);
        flex-basis:130px;
        margin-right:var(--spacing-xxl);
        width:130px;
        height:130px;
    }
    &__container
    {
        @extend .container-fluid;
        
        z-index: 1;
    }
    &__row {
        @extend .l-row;
    }
    &__title
    {
        @include heading-1;
        color: var(--white);
        margin-bottom:var(--spacing-xl);
        
    }
    &__tagline
    {
        @include eyebrow--white;
        margin-bottom:0;
    }
    &__subtitle
    {
        @include subhead;
        color:var(--white);
        margin-bottom:0;
        max-width:60rem;
    }
    &--narrow
    {
        height:30rem;
        @media (max-width:993px)
        {
            height:auto;
        }
    }
    &--centered-bg
    {
        background-position: 50% 50%;
    }
}