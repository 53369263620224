.o-sitemap {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    &__column {
      flex: 1;

      @media screen and (min-width: 768px) {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__list {
      padding-left:0;
        margin-bottom:var(--spacing-xxl);
        list-style: none;
      &--sub {
        padding-left:var(--spacing-xs);
      }
    }

    &__item {
      margin-bottom:var(--spacing-xxs);
    }

    &__link {
      // Styles for the links
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }

      &--heading {
        font-weight:var(--font-bold-weight);
        margin-bottom:var(--spacing-xs);
      }
    }
  }
//REVIEW