.o-search-result-grid {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-xl);
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
 
    &__item {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        width: calc((100% - (var(--spacing-xl) * 2)) / 3);
 
        @media (max-width: 1100px) {
            width: calc((100% - var(--spacing-xl)) / 2);
        }
 
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}