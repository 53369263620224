.o-podcast-listing {
    display: flex;
    align-items: flex-start;
    border:solid 1px var(--line-low-contrast);
    padding: var(--spacing-xxl) 0;
    
    &__img {
        
        // border-radius:100px;
        flex-basis:128px;
        min-width:128px;
        height:128px;
        flex-shrink: 1;
        width:128px;
        margin-left:1rem;
        object-fit: cover;
        aspect-ratio: 1 / 1;
        border-radius:100%;
               
    }
    &__meta {
        margin-left:var(--spacing-xxl);
        flex-basis:75%;
    }
    &__title {
        font-size:var(--font-lg-size);
    }

    &__time {
        @extend .a-muted-text;
        margin-bottom:var(--spacing-xxs);
        display: block;
    }
    &__player {
        padding:var(--spacing-xs) var(--spacing-md);
        background-color:var(--surface-low-contrast);
        display: flex;
        align-items: center;
        padding:var(--spacing-xl) 0 var(--spacing-xl) var(--spacing-xl);
        //border-radius:10px;
        &--hidden {
            display: none;
        }   
        
    }
    &__btn-row {
        margin-bottom:var(--spacing-md);
    }
    &__play-link {
        width:32px;
        height:32px;
        
        flex-basis:32px;
    }
    &__play-img {
        width:32px;
        height:32px;
    }
    &__waveform {
        
        padding-left:var(--spacing-md);
        padding-right:var(--spacing-md);
        border-radius:10px;
        flex-basis: calc(100% - 32px);
        width:80%;
        // display: inline-block;
    }
}

//REVIEW





