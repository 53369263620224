
.a-radio-btn *, .a-radio-btn *::before, .a-radio-btn *::after {
	box-sizing: content-box !important;
}

.a-radio-btn input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.a-radio-btn span {
	line-height: var( --line-height-default);
	font-size: var(--font-md-size);
	font-family: inherit;
}

.a-radio-btn {
	display: table;
	position: relative;
	padding-left: 1.8rem;
	cursor: pointer;
	margin-bottom: var(--spacing-xs);
}

.a-radio-btn input[type="checkbox"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height:var(--spacing-lg);
	width: var(--spacing-lg);
	background: var(--white);
	transition: background 250ms;
	border: 1px solid var(--neutral-400);
	border-radius: 0.125rem;
}

.a-radio-btn input[type="radio"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: var(--spacing-lg);
	width: var(--spacing-lg);
	background: var(--neutral-500);
	transition: background 250ms;
	border: 1px solid var(--neutral-500);
	border-radius: var(--border-radius-xs);
}

.a-radio-btn input[type="checkbox"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .45rem;
	top: .18rem;
	width: var(--spacing-xxs);
	height: .6rem;
	border: solid var(--white);
	border-width: var(--border-width-xs);
	transition: background 250ms;
	transform: rotate(45deg);
}

.a-radio-btn input[type="radio"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: var(--spacing-xxs);
	top: var(--spacing-xxs);
	width: var(--spacing-sm);
	height: var(--spacing-sm);
	border-radius: var(--border-radius-xxxl);
	background: var(--white);
	transition: background 250ms;
}

.a-radio-btn input:disabled ~ .b-input::after {
	border-color: var(--neutral-700);
}

.a-radio-btn input:checked ~ .b-input::after {
	display: block;
}

.a-radio-btn:hover input ~ .b-input,
.a-radio-btn input:focus ~ .b-input {
	background: var(--white-90-opacity);
}

.a-radio-btn input:focus ~ .b-input {
	// box-shadow: 0 0 0 2px rgba(52,144,220,0.5);
	@include focused-no-offset;
}

.a-radio-btn input:checked ~ .b-input {
	background: var(--color-action-color);
	border-color: var(--color-action-color);
}

.a-radio-btn input[type="checkbox"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.a-radio-btn input[type="radio"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.a-radio-btn input[type="radio"]:disabled ~ .b-input::after {
	background: rgba(135, 149, 161, 1);
}

.a-radio-btn input:checked:focus ~ .b-input, .a-radio-btn:hover input:not([disabled]):checked ~ .b-input {
	background: var(--color-action-color);
	// border-color: var(--color-action-color);
	@include focused-offset;
}

.a-radio-btn .b-input::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 3rem;
	height: 3rem;
	margin-left: -0.85rem;
	margin-top: -0.85rem;
	background: var(--color-action-color);
	border-radius: var(--border-radius-xxxl);
	opacity: .6;
	z-index: 99999;
	transform: scale(0);
}

@keyframes b-ripple {
	0% {
		transform: scale(0);
	}

	20% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

@keyframes b-ripple-duplicate {
	0% {
		transform: scale(0);
	}

	30% {
		transform: scale(1);
	}

	60% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

.a-radio-btn input + .b-input::before {
	animation: b-ripple 250ms ease-out;
}

.a-radio-btn input:checked + .b-input::before {
	animation-name: b-ripple-duplicate;
}

.a-radio-btn .b-input::before {
	visibility: hidden;
}

.a-radio-btn input:focus + .b-input::before {
	visibility: visible;
}

.a-radio-btn:first-child .b-input::before {
	visibility: hidden;
}