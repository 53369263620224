.m-single-field-input {

    &__input-wrapper {
        display: flex;
        align-items: center;
        width:100%;
    }
    &__input {
        margin-right:0rem;
        padding: var(--spacing-xs);
        font-weight:var(--font-default-weight);
        border-radius:0px;
        border:solid 1px var(--line-low-contrast);
        transition: border .3s ease-out;
        width:80%;
        height:46px;
        &:hover {
            @include focused-offset;
        }
    }
    &__btn {
        margin-left: var(--spacing-xs);
        white-space: nowrap;
        &:hover {
            .m-search-input__icon {
                fill:var(--color-action-color);
            }
        }
    }
    &__help-text {
        font-size:var(--font-sm-size);
        font-style: italic;
    }
    &__icon {
        height:20px;
        width:20px;
        fill:var(--text-body-text-inverse);
        margin-top:-6px;
    }
    &__heading {
        @include heading-5;
        margin-bottom:var(--spacing-md);
    }
    &--light {
        .m-single-field-input__label {
            color:var(--text-body-text-inverse);
        }
        .m-single-field-input__help-text {
            color:var(--text-body-text-inverse);
        }
        .m-single-field-input__heading {
            color:var(--text-body-text-inverse);
        }

    }

}