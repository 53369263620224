
.article-content h1{
    //font-size:52px;
    font-size: 3.25rem;
    font-weight:$bold;
    @media (max-width: 993px) {
        font-size:32px;
    }
}

.article-content h1{
    //font-size:52px;
    font-size: 3.25rem;
    font-weight:$bold;
    @media (max-width: 993px) {
        font-size:32px;
    }
}

main h2:not([class]){
    //font-size:36px;
    // font-size:2.25rem;
    // font-weight:400;
    margin-top:2.5rem;
    margin-bottom:1.5rem;
    @include heading-2;
    @media (max-width:993px)
    {
        font-size:2rem;
    }
    @media (max-width:767px)
    {
        font-size:1.75rem;
    }
}

.article-content h3{
    //font-size:28px;
    font-size: 1.75rem;
    font-weight:$bold;
}

.article-content h4{
     //font-size:24px;
    margin-bottom:.5rem;
    margin-top:1rem;
    font-size: 1.5rem;
    font-weight:$bold;
}

.article-content h5{
    //font-size:24px;
    //font-size:20px;
    font-size:1.25rem;
    font-weight:$bold;
    margin-bottom:.25rem;
    margin-top:.5rem;
}

.article-content h6 {
    font-size: 1rem;
    font-weight:$bold;
    margin-bottom:.25rem;
    margin-top:.5rem;
}
.article-content h1:first-child, .article-content h2:first-child, .article-content h3:first-child, .article-content h4:first-child, .article-content h5:first-child {
    margin-top:0;
}


.a-heading, .heading, .article-content .a-heading
{
    font-family: $header-font;
    font-weight: 700;
    font-size:2.5rem;
    @media (max-width: 993px) {
        font-size:2rem;
    }
    @media (max-width: 767px) {
        font-size:1.75rem;
    }




    &--centered
    {
        text-align: center;
    }
    &--large
    {
        font-size:3rem !important;
        @media (max-width: 993px) {
            font-size:2.25rem !important;
        }
    }
    &--small
    {
        font-size:2rem !important;
    }

    &--x-small {
        @include heading-3;
        font-family: $header-font;

        margin-top:3rem;
    }
    &--xx-small {
       @include heading-4;

        margin-top:3rem;
    }
    &--x-small:first-child {
        margin-top:0rem;
    }
    &--heading-1 {
      @include heading-1;
    }

    &--heading-2 {
        @include heading-2;
    }
    &--heading-3 {
       @include heading-3;

    }
    &--heading-4 {
        @include heading-4;

    }
    &--heading-5 {
        @include heading-5;

    }

    &--heading-6 {
        @include heading-6;
    }

}


.a-heading--wrap{
    max-width: 18em;
}
.a-heading--regular {
    @extend .a-heading;
    font-weight: var(--font-default-weight); 
    font-size: 2rem;
    margin-bottom: var(--spacing-xxl);// or 'normal'
}
