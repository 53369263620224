.m-timeline
{
    list-style: outside;
    list-style-type: none;
    padding:0;
    margin-left:30px;
    &__item
    {
        padding-top:1.5rem;
        margin-bottom:0;
        position: relative;
    }
    &__item:before
    {
        content:''; 
        display:inline-block; 
        height:100%; 
        width:1em; 
        background-image:url('../images/bullet-line.png'); 
        background-repeat:no-repeat; 
        background-size:1rem auto;
        background-position:right 50% bottom -3px;
        position:absolute;
        left:-30px;
        bottom:0px;
    }
    &__item:first-of-type:before
    {
        content:''; 
        display:inline-block; 
        height:100%; 
        width:1em; 
        background-image:url('../images/bullet-top.png'), url('../images/bullet-line.png'); 
        background-repeat:no-repeat; 
        background-size:1rem auto, 1rem auto;
        background-position:right 50% top 0px, right 50% bottom -3px;
        position:absolute;
        left:-30px;
        bottom:0px;
    }
}