@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ur9mb4');
  src:  url('../fonts/icomoon.eot?ur9mb4#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ur9mb4') format('truetype'),
    url('../fonts/icomoon.woff?ur9mb4') format('woff'),
    url('../fonts/icomoon.svg?ur9mb4#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-experience:before {
  content: "\e96e";
  color: #545454;
}
.icon-icon-place:before {
  content: "\e96f";
  color: #545454;
}
.icon-icon-position:before {
  content: "\e970";
  color: #545454;
}
.icon-cai-logo-full:before {
  content: "\e964";
}
.icon-target:before {
  content: "\e965";
}
.icon-team:before {
  content: "\e966";
}
.icon-telephone-speech-bubble:before {
  content: "\e967";
}
.icon-tools:before {
  content: "\e968";
}
.icon-training-slide:before {
  content: "\e969";
}
.icon-transfer-arrows:before {
  content: "\e96a";
}
.icon-trophy:before {
  content: "\e96b";
}
.icon-two-arrows:before {
  content: "\e96c";
}
.icon-watering-plant:before {
  content: "\e96d";
}
.icon-app-development:before {
  content: "\e900";
}
.icon-Application-Development:before {
  content: "\e901";
}
.icon-Application-Support:before {
  content: "\e902";
}
.icon-arrow-in-cirlce:before {
  content: "\e903";
}
.icon-arrow-up:before {
  content: "\e904";
}
.icon-Artboard-211:before {
  content: "\e905";
}
.icon-assessment:before {
  content: "\e906";
}
.icon-associate-approval:before {
  content: "\e907";
}
.icon-associates-globe:before {
  content: "\e908";
}
.icon-Autism-to-Work:before {
  content: "\e909";
}
.icon-automation:before {
  content: "\e90a";
}
.icon-backpack:before {
  content: "\e90b";
}
.icon-barchart:before {
  content: "\e90c";
}
.icon-bar-graph:before {
  content: "\e90d";
}
.icon-blue-print:before {
  content: "\e90e";
}
.icon-briefcase:before {
  content: "\e90f";
}
.icon-cell-phone:before {
  content: "\e910";
}
.icon-check-box:before {
  content: "\e911";
}
.icon-check-list:before {
  content: "\e912";
}
.icon-circle-chart:before {
  content: "\e913";
}
.icon-clipboard:before {
  content: "\e914";
}
.icon-clock:before {
  content: "\e915";
}
.icon-clock-calendar:before {
  content: "\e916";
}
.icon-collaborative-work:before {
  content: "\e917";
}
.icon-compass:before {
  content: "\e918";
}
.icon-computer:before {
  content: "\e919";
}
.icon-computer-globe:before {
  content: "\e91a";
}
.icon-containment:before {
  content: "\e91b";
}
.icon-desktop-computer-graphs:before {
  content: "\e91c";
}
.icon-desktop-page:before {
  content: "\e91d";
}
.icon-devices:before {
  content: "\e91e";
}
.icon-downward-arrow:before {
  content: "\e91f";
}
.icon-downward-graph:before {
  content: "\e920";
}
.icon-earth:before {
  content: "\e921";
}
.icon-expert-staffing:before {
  content: "\e922";
}
.icon-eyeball-circle:before {
  content: "\e923";
}
.icon-flowchart:before {
  content: "\e924";
}
.icon-folder-papers:before {
  content: "\e925";
}
.icon-gear-magnifying-glass:before {
  content: "\e926";
}
.icon-gear-plant:before {
  content: "\e927";
}
.icon-gears:before {
  content: "\e928";
}
.icon-gear-window:before {
  content: "\e929";
}
.icon-globe-speech-bubbles:before {
  content: "\e92a";
}
.icon-google:before {
  content: "\e92b";
}
.icon-hand-shake:before {
  content: "\e92c";
}
.icon-health-cross:before {
  content: "\e92d";
}
.icon-heart-in-hands:before {
  content: "\e92e";
}
.icon-innovation:before {
  content: "\e92f";
}
.icon-interview:before {
  content: "\e930";
}
.icon-iot:before {
  content: "\e931";
}
.icon-IT-Ed-Courseware:before {
  content: "\e932";
}
.icon-Knowledge-Capture:before {
  content: "\e933";
}
.icon-laptop-page:before {
  content: "\e934";
}
.icon-laptop-profile:before {
  content: "\e935";
}
.icon-letter-clipboard:before {
  content: "\e936";
}
.icon-letter-paper:before {
  content: "\e937";
}
.icon-lightbulb:before {
  content: "\e938";
}
.icon-lightbulb-idea:before {
  content: "\e939";
}
.icon-lightning:before {
  content: "\e93a";
}
.icon-line-chart:before {
  content: "\e93b";
}
.icon-magnifying-glass:before {
  content: "\e93c";
}
.icon-Managed-Staffing-Services:before {
  content: "\e93d";
}
.icon-market:before {
  content: "\e93e";
}
.icon-mobile-retail:before {
  content: "\e93f";
}
.icon-mobile-speech-bubbles:before {
  content: "\e940";
}
.icon-money-plant:before {
  content: "\e941";
}
.icon-newsfeed:before {
  content: "\e942";
}
.icon-optimization:before {
  content: "\e943";
}
.icon-paper:before {
  content: "\e944";
}
.icon-paper-gear:before {
  content: "\e945";
}
.icon-paper-magnifying-glass:before {
  content: "\e946";
}
.icon-people-in-circles:before {
  content: "\e947";
}
.icon-person-desk:before {
  content: "\e948";
}
.icon-person-id:before {
  content: "\e949";
}
.icon-plus-in-circle:before {
  content: "\e94a";
}
.icon-presentation:before {
  content: "\e94b";
}
.icon-professional-assessment:before {
  content: "\e94c";
}
.icon-puzzle-pieces:before {
  content: "\e94d";
}
.icon-QA-and-Testing:before {
  content: "\e94e";
}
.icon-robot:before {
  content: "\e94f";
}
.icon-roi:before {
  content: "\e950";
}
.icon-SAP-ERP-Practice:before {
  content: "\e951";
}
.icon-savings:before {
  content: "\e952";
}
.icon-scaleable-support:before {
  content: "\e953";
}
.icon-security:before {
  content: "\e954";
}
.icon-security-computer:before {
  content: "\e955";
}
.icon-Service-Desk:before {
  content: "\e956";
}
.icon-servicenow-now:before {
  content: "\e957";
}
.icon-servicenow-on-button:before {
  content: "\e958";
}
.icon-shield:before {
  content: "\e959";
}
.icon-shipping-cart:before {
  content: "\e95a";
}
.icon-side-bar-chart:before {
  content: "\e95b";
}
.icon-simple-page:before {
  content: "\e95c";
}
.icon-speech-bubbles:before {
  content: "\e95d";
}
.icon-Staff-Augmentation:before {
  content: "\e95e";
}
.icon-staff:before {
  content: "\e95f";
}
.icon-stethoscope:before {
  content: "\e960";
}
.icon-sun-cloud:before {
  content: "\e961";
}
.icon-support:before {
  content: "\e962";
}
.icon-switch-board:before {
  content: "\e963";
}
