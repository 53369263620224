.m-social-post-card {
    background-color:$lightest-gray;
    padding:1rem 2rem 2rem 1rem;
    border-radius:10px;
    position: relative;
    &__img {
        height:32px;
        width:32px;
    }
    &__message {
        font-size: 1rem;
        text-align: left;
        margin: 1rem 0 .25rem 0;
        color:$dark-gray;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        overflow-wrap: anywhere;
    }
    &__message:hover {
        color:$dark-gray;
    }
    &__subtitle {
        font-size:1rem;
        text-align: center;
        margin-top:0;
        margin-bottom: 2rem;
        color:$medium-gray;
    }
    &__engagement-meta {
        display: flex;
        flex-direction: row;
        margin-top:.75rem;
    }
    &__count {
        padding:4px 12px 4px 32px;
        margin-right:.5rem;
        border-radius:6px;
        background: darken($lightest-gray, 3%);
        font-size: 0.8rem;
        background-position: 8px center;
        background-size: 16px 16px;
        background-repeat: no-repeat;
        &--impressions {
            background-image: url('../images/impression-color.png');
        }
        &--likes {
            background-image: url('../images/like-color.png');
        }
        &--clicks {
            background-image: url('../images/click-color.png');
        }
        &--shares {
            background-image: url('../images/share-color.png');
        }
    }
    &__timestamp {
        position: absolute;
        top:18px;
        font-size: 0.9rem;
        right:16px;
        color:$medium-gray;
    }
}

