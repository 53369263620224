.a-chip {
    background-color: var(--color-action-color); /* Updated from $action-color */
    color: var(--text-accent-complement); /* Updated for better contrast on action color background */
    // Utilizing spacing variables for padding
    padding: var(--spacing-xs) var(--spacing-sm); /* Updated from .5rem .75rem */
    // Using circle border-radius
    border-radius: 100px;
    line-height: 1; 
    display: inline-flex;
    align-items: center;
    border: none;

    &__icon {
        height:12px;
        transition: fill 0.2s ease-out;
        fill:rgba(255,255,255,0.8);
    }
    &__icon:hover {
        fill:rgba(255,255,255,1);
    }
    &__remove-btn {
        background-color: transparent;
        border:none;
        padding:var(--spacing-xxs);
    }
    &__remove-btn:hover {
        .a-chip__icon {
            fill:rgba(255,255,255,1);
        }
    }
    &__label {
        margin: .4rem var(--spacing-xs) var(--spacing-xxs) var(--spacing-xxs); /* Updated from .4rem .5rem .25rem .25rem */
        font-size: var(--font-xs-size); /* Updated from 0.8rem */
        white-space: nowrap;
        // Setting font weight to bold
        font-weight: var(--font-bold-weight); /* Updated from $bold */
    }
    &__category {
        font-weight:var(--font-default-weight);
        display: none;
    }

    &--action:hover {
        .a-chip__label {
            text-decoration: underline;
        }
    }

.a-chip:focus {
    
    @include focused-no-offset;
}
}

//REVIEW Lable padding
