.a-tag {
    background-color:rgba(0,0,0,0.04);
    padding:var(--spacing-xxs) var(--spacing-xs);
    border-radius:100px;
    line-height: var(--line-height-condensed);
    display: inline-flex;
    align-items: center;
    &__icon {
        height:16px;
        fill:var(--color-dark-accent-color)
    }
    &__label {
        margin:var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xxs) var(--spacing-xs);
        font-size:var(--font-sm-size);
        color:var(--text-body-text);
    }
    &--important {
        .a-tag__icon {
            fill: var(--color-warning);
        }
    }
}
