.m-resource-highlight {
    max-width:750px;
    &__img-wrapper {
        position: relative;
    }
    &__img-wrapper::after {
        display: block;
        content: '';
        /* 16:9 aspect ratio */
        padding-bottom: 56.25%;
        }
    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &__tag {
        background-color: var(--bright-blue);
        color:var(--color-body-bg);
        position: absolute;
        bottom:0;
        left:0;
        display: inline-block;
        padding:var(--spacing-xs) var(--spacing-md);
        &--yellow {
            background-color:var(--bright-sunflower);
        }
        &--purple {
            background-color:var(--lavender-blue);
        }
        &--red {
            background-color: var(--color-warning);
        }
        &--green {
            background-color:var(--apple-green);
        }
    }
    &__title {
        font-size:var(--font-lg-size);
        margin-top:var(--spacing-md) !important;
        color:var(--text-heading-text);
    }
    &__excerpt {
        font-weight: var(--font-default-weight);
        color:var(--text-body-text);
    }
}

.m-resource-highlight:hover {
    .m-resource-highlight__title {
        color:var(--color-action-color);
    }
}

//REVIEW 