.m-file-download-card {
    background-color:#ececec;
    border-radius:0px;
    padding:1rem 1rem;
    &__title {
        font-size:1rem !important;
        font-family: $body-font;
        font-weight:$bold !important;
    }
    &__inner {
        display: flex;
        flex-direction: row;
    }
    &__image {
        flex-basis: 64px;
        width: 64px;
        height:64px;
        object-fit: cover;
        border-radius: 100px;
        max-width:64px;
        max-height:64px;
    }
    &__info {
        margin-left:20px;
        padding-top:.5rem;
    }
    &__link {
        font-size:1rem;
        color:$dark-gray;
    }
    &__link:hover {
        color:$action-color;
        text-decoration: underline;
    }
    

}