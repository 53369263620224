.o-sidebar {
    
    padding: var(--spacing-xl);
    
    &--sticky {
            position: -webkit-sticky; /* Safari */
            position: sticky;
            top: 100px;
    }
    &--gray {
        background-color:var(--neutral-100);
    }
    &__stat {
        margin-bottom:3rem;
        &--compressed {
            margin-bottom:var(--spacing-xxl);
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__cal-item {
        margin: var(--spacing-xl) 0;
    }
    &__cal-item-title {
        font-weight:var(--font-default-weight);
        font-size:var(--font-md-size);
        margin-top:var(--spacing-xs);
        
    }
    &__cal-item-desc {
        margin-bottom: var(--spacing-xs);
        font-size: var(--font-sm-size);
    }
    &__cal-item-link {
        font-size:var(--font-sm-size);
    }
    &__cal-item-date {
        color:var(--bright-blue);
        text-transform: uppercase;
        font-size:var(--font-sm-size);
        font-weight:var(--font-default-weight);
    }

}