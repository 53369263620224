.o-resource-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: var(--spacing-sm);  // Add consistent gap
    @media (max-width:767px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

     }
    &__highlight {
        margin-top: var(--spacing-md);
        flex: 0 0 calc((100% - (2 * var(--spacing-sm))) / 3);  // Correct math for 3 columns
        width: 100%;
        @media (max-width:993px) {
            flex: 0 0 calc((100% - var(--spacing-sm)) / 2);  // Correct math for 2 columns
         }

    }
}