.o-social-summary-card {
    
    background-color:$lightest-gray;
    padding:2rem 1rem;
    border-radius:10px;
    &__inner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    &__stat {
        // flex-basis: calc(25% - 15px);
        flex-basis:280px;
        margin-left:7.5px;
        margin-right:7.5px;

    }
    &__title {
        font-family: $header-font;
        font-size: 1.5rem;
        text-align: center;
        margin: 1rem 1rem .25rem 1rem;
    }
    &__subtitle {
        font-size:1rem;
        text-align: center;
        margin-top:0;
        margin-bottom: 2rem;
        color:$medium-gray;
    }
} 