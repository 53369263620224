.m-featured-article
{
    background-position: 50% 50%;
    background-size:cover;
    padding-top:10rem;
    position: relative;
    &--tall
    {
        padding-top:12rem;
    }
    &--video
    {
        padding-top:56.25%;
    }
    &__video {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
    }
    &__meta-block
    {
        background-color:var(--neutral-800);
        padding: var(--spacing-xxl);;
        margin-top:8rem;
        color:var(--white);
        margin-left:10%;
        margin-right:40%;
        position: relative;
        bottom:-5rem;
        text-align:left;
        @media (max-width:993px)
        {
            margin-left:5%;
            margin-right:5%;
            text-align: center;
            padding: var(--spacing-xxl) var(--spacing-md) var(--spacing-xxl) var(--spacing-md);
        }
        &--light-blue
        {
            color:var(--dark-blue);
            background-color:var(--lightest-blue);
            @if $high-contrast == true {
                background-color:var(--neutral-800) !important;
            }
        }
        &--teal
        {
            color:var(--white);
            background-color:var(--apple-green);
            @if $high-contrast == true {
                background-color:var(--neutral-800) !important;
            }
        }
        &--right-aligned
        {
            margin-left:40%;
            margin-right:10%;
        }
        &--wide
        {
            margin-left:5%;
            margin-right:20%;
            bottom:-4rem;
            @media (max-width:$wide-breakpoint-min)
            { 
                margin-bottom:6rem;
                margin-left:5%;
                margin-right:5%;
            }
        }
        &--wide-video
        {
            margin-left:5%;
            margin-right:20%;
            bottom:-4rem;
            position:absolute;
            width:70%;
            @media (max-width:$wide-breakpoint-min)
            { 
                left:15px;
                right:15px;
                margin-right:0;
                margin-left:0;
                width:auto;
            }
        }
    }
    &__title
    {
        font-size:var(--font-3xl-size);
        font-family:var(--heading-fonts);
        font-weight:var(--font-bold-weight);
        @media (max-width:993px)
        {
            font-size:2.25rem;
        }
    }
    &__tag
    {
        color:var(--neutral-400);
        text-transform: uppercase;
        margin-bottom:var(--spacing-xxl);
        display: block;
    }
    &__link
    {
        margin-top:var(--spacing-md);
        display: inline-block;
        color:var(--white);
        padding: var(--spacing-xs) var(--spacing-xxl);
        border:solid 1px var(--white);
        border-radius:100px;
        &--light-blue
        {
            color:var(--dark-blue);
            border:solid 1px var(--dark-blue);
        }
        &--teal
        {
            color:var(--white);
            border-color:var(--white);
        }
    }
}