.a-text-input
{
    padding:var(--spacing-xs);
    font-weight:var(--font-default-weight);
    border-radius:0px;
    border:solid 1px var(--line-low-contrast);
    transition: border .3s ease-out;
    width:100%;
}

.a-text-input:focus-visible
{
     @include focused-offset;
}