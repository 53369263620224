.m-photo-hero-slide {
    background-color:var(--surface-low-contrast);
    height:500px;
    border-radius: 0px;
    position: relative;
    background-size: cover;
    background-position: center center;
    &__prev {
        color: rgba(255,255,255,0.8) !important;
    }
    &__next {
        color: rgba(255,255,255,0.8) !important;
    }
    &__content {
        padding:var(--spacing-xxl) 5rem;
        @media (max-width:993px) {
            padding:var(--spacing-xxl) var(--spacing-3xl);
        }
        @media (max-width:776px) {
            padding: var(--spacing-xxl) var(--spacing-xxl);
        }
        position: absolute;
        height:100%;
        width:100%;
        top: 0px;
        left:0px;
        background: rgb(0,0,0);
        background: linear-gradient(30deg,rgba(0,0,0,.634331) 0,rgba(0,0,0,.467945) 51%,transparent 100%);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start
    }
    &__tag {
        @include eyebrow--white;
        z-index: 5;
        margin-bottom: 0;
    }
    &__title {
        color:var(--text-body-text-inverse);
        font-size:var(--font-3xl-size);
        
        width:70%;
        @media (max-width:993px) {
            font-size: var(--font-xxl-size);
            width:80%;
        }
        @media (max-width:576px) {
            font-size: var(--font-xxl-size);
            width:90%;
            margin-bottom: var(--spacing-3xl);
        }
    }
    &__text {
        color:var(--text-body-text-inverse);
        font-size:var(--font-md-size);
        width:80%;
        @media (max-width:993px) {
            width:90%;
        }
        @media (max-width:576px) {
            display: none;
        }
    }
   
    // width: 90%;
}

//REVIEW