// TODO: Remove margin from block and build a parent block

.m-article-highlight
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding:var(--spacing-md);
    flex-wrap: wrap;
    &--featured {
        background-color: var(--surface-low-contrast);
        border-radius: var(--border-radius-sm);
        padding:var(--spacing-xl) var(--spacing-md);
    }
    @media (max-width:993px)
    {
        flex-direction:column;
    }
    margin-top:var(--spacing-3xl);
    &__featured-tag {
        flex-basis: 100%;
        color: var(--text-body-text);
        font-weight: var(--font-default-weight);
        text-transform: uppercase;
    }
    &__title
    {
        @include heading-4;
        color:var(--text-heading-text);
        transition: all .2s ease-out;
        font-family: var(--heading-fonts);
        font-weight:var(--font-bold-weight);
        margin-top:0;

        @media (max-width:767px)
        {
            font-size:var(--font-xxl-size);
        }
    }
    &__image-wrapper
    {
        flex-basis:30%;
    }
    &__image
    {
        width:100%;
        height:auto;
        object-fit: cover;
    }
    
    &__details-wrapper
    {
        flex-basis:65%;
        color:var(--text-heading-text);
    }
    &__tag
    {
        color: var(--bright-blue);
        @if $high-contrast == true {
            color:var(--text-heading-text) !important;
        }
        padding: var(--spacing-xxs) 0rem;
        display: inline-block;
        border-radius: 6px;
        font-weight: var(--font-default-weight);
        font-size: var(--font-md-size);
        margin-bottom: var(--spacing-xs);
    }
}

.m-article-highlight:hover .m-article-highlight__title
{
    color:var(--color-action-color);
}

.m-article-highlight:first-of-type
{
    margin-top:0;
}
