.a-callout {
    display: block;
    background-color: var(--surface-low-contrast);
    padding:var(--spacing-md) 5% var(--spacing-md) 64px;
    border-radius: var(--border-radius-md);
    background-image:url('../images/information.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position: 16px center;
    margin: var(--spacing-xl) 0;
    &--info {
        background-color: var(--surface-low-contrast-accent);
    }
    // &--important {
    //     background-color: #ffeab5;
    //     background-image:url('../images/important.png');
    // }
    // TODO: Revisit whether we use this on Cait
}