.m-toc {
    
    position: relative;
    padding-bottom:2rem;
    border-right:solid 1px $lightest-gray;
    @media (max-width: 767px) { 
       border-right:none;
       padding-left:2rem;
       padding-right:2rem;
       padding-top:1rem;
       background-color: $lightest-gray;
     }
    &__item {
        margin-bottom:0.75rem;
        color:$action-color;
        position: relative;
        &--active:before
        {
            content:''; 
            display:block; 
            height:24px; 
            width:24px;; 
            background-image:url('../images/right-arrow-circle.png') !important; 
            background-size:contain; 
            background-repeat:no-repeat; 
            position: absolute;
            left:-28px;
            top:0px; 
        }

    }
    &__item:before {
        content:''; 
        display:block; 
        height:24px; 
        width:24px;; 
        background-image:url('../images/right-arrow-circle-no-fill.png'); 
        background-size:contain; 
        background-repeat:no-repeat; 
        position: absolute;
        left:-28px;
        top:0px; 

    }
    &__list {
        padding-left: 16px;
        list-style: none;
    }
    &__toggle {
        position: absolute;
        top:20px;
        left:16px;
        transition: all 0.2s ease-in;
        @media (min-width: 768px) { 
            display: none;
         }
    }
    &__toggle--expanded {
        transform: rotate(45deg);
        transition: all 0.2s ease-in;
    }
    &__toggle-img {
        width:24px;
        height:auto;
        fill:$action-color;

    }
    &__title {
        @media (max-width: 767px) { 
            margin-left:24px;
         }
    }
    &__fade {
        height:64px;
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        background: rgb(245,245,245);
        background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 0.3) 39%, rgba(245, 245, 245, 0.9) 80%, whitesmoke 100%);
        text-align: center;
        @media (min-width: 768px) { 
            display: none;
         }
    }
    &__collapse-toggle {
        position: absolute;
        bottom:0;
        display: inline-block;
        
        
    }
    &--collapsed {
        @media (max-width: 767px) { 
            max-height:256px;
            overflow-y:hidden;
         }
    }
    &__link {
        color:$action-color;
        font-weight:400;
        &--active {
            font-weight:$bold;
        }
    }
    &__link:hover {
        color:$action-color;
        // font-weight:600;
    }
}
