.l-row
{
    @extend .row;
}

.l-one-col
{
    @extend .col-md-12;
}

.l-no-padding {
    padding:0;
}

.l-one-col-narrow
{
    @extend .col-lg-6;
    @extend .offset-lg-3;
    @extend .col-md-8;
    @extend .offset-md-2;
}
.l-one-col-medium
{
    @extend .col-lg-8;
    @extend .offset-lg-2;
    @extend .col-md-10;
    @extend .offset-md-1;
}

.l-one-half-col
{
    @extend .col-md-6;
    &:not(:first-child) {
        @media (max-width:767px)
        {
            margin-top:2rem;
        }
    }

}

.l-two-thirds-col
{
    @extend .col-md-8;
    &:not(:first-child) {
        @media (max-width:767px)
        {
            margin-top:2rem;
        }
    }
}
.l-one-thirds-col
{
    @extend .col-md-4;
    &:not(:first-child) {
        @media (max-width:767px)
        {
            margin-top:2rem;
        }
    }
}

.l-one-fourths-col
{
    @extend .col-md-3;
    &:not(:first-child) {
        @media (max-width:767px)
        {
            margin-top:2rem;
        }
    }
}

.l-three-fourths-col
{
    @extend .col-md-9;
    &:not(:first-child) {
        @media (max-width:767px)
        {
            margin-top:2rem;
        }
    }
}

.l-mt-none
{
    margin-top:0  !important;
}

.l-mt-700
{
    margin-top:700px;
}

.l-mt-1000
{
    // margin-top:700px;
    // height:100%;
}

.l-mt-xs
{
    margin-top:1rem;
}
.l-mt-sm
{
    margin-top:2rem;
}

.l-mt-md
{
    margin-top:4rem;
}

.l-mt-lg
{
    margin-top:6rem;
}

.l-mt-xl
{
    margin-top:9rem;
}

.l-mt-neg-xs
{
    margin-top:-1rem;
}
.l-mt-neg-sm
{
    margin-top:-2rem;
}

.l-mt-neg-md
{
    margin-top:-4rem;
}

.l-mt-neg-lg
{
    margin-top:-6rem;
}

.l-mt-neg-xl
{
    margin-top:-9rem;
}

.l-mb-none
{
    margin-bottom:0 !important;
}

.l-mb-xs
{
    margin-bottom:1rem;
}

.l-mb-sm
{
    margin-bottom:2rem;
}

.l-ml-none {
    margin-left: 0;
}

.l-mr-none {
    margin-right: 0;
}

.l-mb-md
{
    margin-bottom:4rem;
}

.l-mb-lg
{
    margin-bottom:6rem;
}

.l-mb-xl
{
    margin-bottom:9rem;
}

.l-pt-none
{
    padding-top:0 !important;
}

.l-pt-sm
{
    padding-top:2rem;
}

.l-pt-md
{
    padding-top:4rem;
}

.l-pt-lg
{
    padding-top:6rem;
}

.l-pl-xl
{
    padding-top:9rem;
}

.l-pl-xs
{
    padding-left:1rem;
}

.l-pl-sm
{
    padding-left:2rem;
}

.l-pl-md
{
    padding-left:4rem;
    @media (max-width: 767px) {
        padding-left: 1rem;
    }
}

.l-pl-lg
{
    padding-left:6rem;
}

.l-pl-xl
{
    padding-left:9rem;
    @media (max-width: 767px) {
        padding-left: 1rem;
    }
}

.l-pr-xl
{
    padding-right:9rem;
}

.l-pr-xs
{
    padding-right:1rem;
}

.l-pr-sm
{
    padding-right:2rem;
}

.l-pr-md
{
    padding-right:4rem;
    @media (max-width: 767px) {
        padding-right: 1rem;
    }
}

.l-pr-lg
{
    padding-right:6rem;
}

.l-pr-xl
{
    padding-right:9rem;
    @media (max-width: 767px) {
        padding-right: 1rem;
    }
}

.l-pb-none
{
    padding-bottom:0 !important;
}

.l-pb-sm
{
    padding-bottom:2rem;
}


.l-pb-md
{
    padding-bottom:4rem;
}

.l-pb-lg
{
    padding-bottom:6rem;
}

.l-pb-xl
{
    padding-bottom:9rem;
}

.l-pl-none
{
    padding-left:0;
}

.l-pr-none
{
    padding-right:0;
}

.l-order-first {
    order: -1;
}
