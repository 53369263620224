.o-tldr {
    border:solid 2px var(--neutral-300);
    padding:var(--spacing-xl);
    position: relative;
    
    &__inner {
        overflow-y: hidden;
        max-height: 150px;
        transition: all 0.3s ease-out;
        &--expanded {
            max-height: 9999px;
        }
    }
    &__label {
        font-size:var(--font-md-size);
        background-color: var(--white);
        display: inline-block;
        padding:0 var(--font-md-size);
        // transform: translateY(-2.25rem);
        position: absolute;
        top:-.75rem;
        left:var(--spacing-xs);
        color: var(--deep-sea-blue);
        font-weight: var(--font-bold-weight);
    }
    &__heading {
        margin-top:var(--spacing-md);
    }
    &__toggle {
        position: absolute;
        bottom:8px;
        left:var(--spacing-xl);
        font-weight: var(--font-bold-weight);
    }
    &__mask {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height:150px;
        filter: blur(4px);
        background: var(--white);
        background: linear-gradient(0deg, rgba(255,255,255,1) 12%, rgba(255,255,255,0.8113620448179272) 59%, rgba(255,255,255,0) 85%);
    }
}