.a-icon {
    padding:0;
    // border-radius:100px;
    background-color:transparent;
    transition: all 0.2s ease-in;
    border:none;

    &__img {
        width:44px;
        height:44px;
        padding:var(--spacing-sm);
        text-align: center;
        fill:  var(--color-action-color);
    }
    &--red {
        .a-icon__img {
            fill: var(--vivid-vermilion-darken-20);
        }

    }

}
.a-icon:hover {

    // background-color:rgba(0,113,188,0.1);

}
.a-icon:focus {
    @include focused-no-offset;
}

.a-icon--red:hover {

    // background-color:rgba(162,25,0,0.1);

}