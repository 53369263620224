.o-search-result
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding:var(--spacing-xl) 0 var(--spacing-xl) var(--spacing-xl);
    @media (max-width: 993px) {
        padding-right: var(--spacing-xl);
     }
    // flex-wrap: wrap;
    background-color: var(--surface-body-bg);
    border:solid 1px var(--line-low-contrast);
    transition: box-shadow 0.3s ease-out;
    min-height:150px;

    &--in-grid {
        margin-bottom: 0;
        height: 100%;
        width: 100%;

        .o-search-result__link {
            margin-top:var(--spacing-xs);
        }
    }
    &--featured {
        background-color: var(--surface-low-contrast);
        border-radius: var(--border-radius-sm);
        padding:var(--spacing-xl) var(--spacing-md);
    }
    &--no-min-height {
        min-height: auto;
    }
    &--full-bleed {
        padding: 0;

        .o-search-result__details-wrapper {
            padding:var(--spacing-lg) 0 var(--spacing-lg) var(--spacing-lg);
        }

    }

    @media (max-width:993px)
    {
        flex-direction:column;
        min-height:auto;
    }
    margin-bottom:var(--spacing-xl);
    &__featured-tag {
        flex-basis: 100%;
        color:var(--text-body-text-muted);
        font-weight: var(--font-default-weight);
        text-transform: uppercase;
    }
    &__time {
        @include subhead;
        margin-bottom:var(--spacing-xxs);
        display: block;
    }

    &__title
    {
       @include heading-5;
        color:var(--text-body-text);
        transition: all .2s ease-out;
        font-family: var(--heading-fonts);
        margin-top:0;
    }
    &__image-wrapper
    {
        flex-basis:25%;
        @media (max-width: 993px) {
            // flex-basis:175px;
         }
    }
    &__image
    {
        width:100%;
        height: 100%;
        object-fit: cover;
        @media (max-width: 993px) {
            aspect-ratio: 2.7 / 1;
         }
    }
    &__details-wrapper
    {
        flex-basis:55%;
        color:var(--text-body-text);
        @media (max-width: 993px) {
            margin-top:var(--spacing-md);
         }
    }

    &__cta-wrapper
    {
        flex-basis:15%;
        color:var(--text-body-text);

        border-left:solid 1px var(--line-low-contrast);
        @media (max-width: 993px) {
            display: none;
        }
    }
    &__cta {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height:100%;
    }
    &__excerpt {
        color:var(--text-body-text);
    }
    &__tag
    {
        @include eyebrow;
        padding: 0 0 var(--spacing-xxs) 0;
        display: inline-block;
        border-radius: var(--border-radius-sm);
        font-weight: var(--font-bold-weight);
        font-size: var(--font-md-size);
    }
    &__link {
        display: block;
        color:var(--color-action-color);
        margin-top:var(--spacing-xxl);
        &:hover {
            color: var(--color-action-color);
        }
    }
    &__link::after {
        content:' →';
    }
    &--no-image {
        .o-search-result__details-wrapper {
            flex-basis:80%;
        }
    }
    &--no-cta {
        .o-search-result__details-wrapper {
            flex-basis: calc(70% + var(--spacing-xl));
            padding-right: var(--spacing-md);
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.o-search-result--no-cta.o-search-result--no-image {
    .o-search-result__details-wrapper {
        flex-basis:calc(95% + var(--spacing-xl));
        padding-right:var(--spacing-md);
    }
}

.o-search-result:hover {
    -webkit-box-shadow: var(--shadow-medium);
    box-shadow: var(--shadow-medium);
}
.o-search-result:hover .o-search-result__link {
    text-decoration: underline;
}
.o-search-result:hover .o-search-result__title
{
    color:var(--text-body-text) !important;
}

.o-search-result:hover .a-icon-button__label
{
    color:var(--color-action-color);
}


.o-search-result:first-of-type
{
    margin-top:0;
}

