.o-link-bar {
    background-color: var(--surface-body-bg);
    border-radius: 0px;
    padding-top:var(--spacing-md) !important;
    padding-bottom:var(--spacing-md) !important;
    margin:0;
    padding-left:var(--spacing-md)!important;
    padding-right:var(--spacing-md) !important;
    -webkit-box-shadow: 2px 2px 20px 1px rgba(0,0,0,0.15); 
    box-shadow: 2px 2px 20px 1px rgba(183, 107, 107, 0.15);
    // display: flex;
    // flex-direction:row;
    // justify-content: center;
    @media (max-width: 767px) { 
        // overflow-x:scroll;
     }
    &__item {
        // list-style: none;
        // flex-basis: 128px;
        // margin-right:16px;
        text-align: center;
        
        border-radius:0px;
        transition: all .2s ease-in;
        background-color: transparent;
    }
    &__item:hover {
        background-color:var(--surface-low-contrast);
        transition: all .2s ease-in;
        &>.o-link-bar__label {
            color:var(--color-action-color);
        }
    }
    &__item:hover .o-link-bar__label {        
        color:var(--color-action-color);
        font-weight:var(--font-bold-weight);
    }

    &__img {
        display: inline-block;
        width:32px;
        height:32px;
        margin-bottom:8px;
    }
    &__label {
        font-size:var(--font-sm-size);
        display: block;
        color:var(--text-body-text);
        font-weight:var(--font-default-weight);
        line-height: var(--line-height-condensed);
        transition: all .2s ease-in;
    }
    &__link {
        height:100%;
        width:100%;
        padding:var(--spacing-xs) var(--spacing-md);
        display: flex;
        flex-direction: column;
        align-items:center;
    }
}