.o-filter {
    // position: relative;
    &__group {
        border-top:solid 1px var(--line-low-contrast);
        padding: var(--spacing-md) 0;
        &--expanded {
            .o-filter__group-contents {
                display: block;
            }
            .o-filter__collapse-icon-vertical {
                opacity: 0;
            }
        }
    }
    &__group:first-child {
        border-top: none;
    }
    &__group-heading {
        @include heading-5;
        display:flex;
        justify-content: space-between;
        align-items: center;
        padding:0 0 0rem 0;
    }
    &__fixed-btn-row {
        position: absolute;
        padding:var(--spacing-md);
        bottom:0px;
        right:0px;
        width:100%;
        background-color: var(--color-action-color-complement);
    }
    &__group-contents {
        display:none;
        margin-top:var(--spacing-md);
    }
    &__group-collapse-toggle {
        align-items: center;
        cursor: pointer;
        border: none;
        background-color: transparent;
        // min-height: 44px;
    }
    &__group-collapse-toggle:focus {
        @include focused-no-offset;
    }
    &__collapse-icon
    {
        margin-right:var(--spacing-xs);
        margin-left:var(--spacing-xs);
        height:16px !important;
        flex-basis:16px;
        fill:var(--color-action-color);
    }


}