.m-stat
{
    // @extend .col;
    text-align: center;
    &--left-aligned {
        text-align: left;
    }
    &__value
    {
        @include heading-2;
        display: block;
        &--small {
            font-size:1rem;
            font-weight:600;
        }
    }
    &__label
    {
        @include body;
        display: block;
        
    }
    &__icon
    {
        display: block;
        height:32px;
        width:32px;
        margin: .5rem auto;
        &--left-aligned {
            margin: .5rem auto .5rem 0;       
        } 
    }
}