.m-share-block {
    padding:var(--spacing-md) 0;
    @media (max-width:767px)
        {
            flex-wrap: wrap;
        }
    &__label {
        font-weight:var(--font-default-weight);
        color:var(--text-body-text);
        margin-right: var(--spacing-md);
        display: block;
        @media (max-width:767px)
        {
            flex-basis:100%;
        }
    }
    &__share-link {
        flex-basis:44px;
        margin: 0rem var(--spacing-xxs);
        display: inline-block;
    }
    &__share-link:first-child {
        margin-left:0;
    }
    &__icon {
        width:44px;
        height:auto;
        fill:#585858;
        opacity: 1;
        transition: all .5s ease-out;
        padding:6px;
    }
    &__icon:hover {
        opacity: 0.8;
    }
    &__icon-wrapper{
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
    }
}