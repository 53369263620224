.o-hero-slider {
    padding-bottom:2.5rem !important;
    &__prev {
        position: absolute !important;
        bottom:0px !important;
        right:32px !important;
        left:inherit !important;
        top:inherit !important;
        height:32px !important;
        width:auto !important;
        font-size:24px !important;
        
    }
    &__prev::after {
        font-weight:900;
        font-size:24px !important;
        color:$action-color;
    }
    &__next {
        position: absolute !important;
        bottom:0px !important;
        right:0px !important;
        left:inherit !important;
        top:inherit !important;
        height:32px !important;
        width:auto !important;
        font-size:24px !important;
        
    }
    &__next::after {
        font-weight:900;
        font-size:24px !important;
        color:$action-color;
    }
    &__pagination {

    }
    .swiper-pagination {
        bottom:3px !important;
    }

    .swiper-pagination-bullet-active {
        background-color:$action-color !important;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: 0.35 !important;
        fill:$dark-gray !important;
        color:$dark-gray !important;
    }
    .swiper-button-next.swiper-button-disabled:after, .swiper-button-prev.swiper-button-disabled:after {
        fill:$dark-gray !important;
        color:$dark-gray !important;
    }
}