.o-video-hero {
    // @extend .jumbotron;
    // @extend .jumbotron-fluid;
    &__overlay {
        height:100vh;
        background:var(--neutral-900);
        background: linear-gradient(180deg, rgba(32,32,32,0) 0%, rgba(32,32,32,0.5039299665178572) 78%, rgba(32,32,32,0.7591680617559523) 100%);
        z-index: 5;
        position:fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    &__wrapper
    {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
        pointer-events: none;
        overflow: hidden;
        background-size: cover;
        background-repeat: no-repeat;
    }
    &__iframe {
        width: 100vw;
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
    &__content {
        height:100vh;
        padding-top:5rem;
        @extend .jumbotron;
        @extend .jumbotron-fluid;
        background-color: transparent;
        padding: 10rem 5rem 5rem 5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        @media (max-width: 992px) {
            padding: 7rem var(--spacing-md) 5rem var(--spacing-md);
        }
    }
    &__title {
        color:var(--white);
        line-height: 1.3;
        font-weight:900;
        font-size:3.5rem;
        margin-bottom:1rem;
        @media (max-width: 992px) {
            font-size:2.5rem;
        }
    }
    &__intro {
        color:var(--white);
        font-weight: var(--font-default-weight);
        font-size:var(--font-xl-size);
        width:80%;
    }
    &__button {
        color:white;
        padding:var(--spacing-md) var(--spacing-xxl);
        border: solid 1px white;
        border-radius:100px;
        display: inline-block !important;
        margin-top:var(--spacing-md);
        font-size:var(--spacing-lg);
        font-weight:var(--font-default-weight);
        transition: all .2s ease-out;
    }
    &__button:hover {
        color:white;
        background-color: rgba(255,255,255,0.4);
        transition: all .2s ease-out;
    }
}