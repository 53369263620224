.o-search-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    background-size:cover;
    background-position-x: right;
    background-position-y: center;
    background-color:transparent;
    text-align: center;
    @if $high-contrast == true {
        background-color:var(--neutral-800);
    }
    background-repeat:no-repeat;
    // padding-top:10rem;
    // padding-bottom:10rem;
    height:375px;
    padding-top:140px;
    position: relative;
    @media (min-width: 1000px) {
        padding-top:125px;
        padding-bottom:5rem;
        height:300px;
     }
     &__greeting {
         text-align: center;
         color:var(--text-heading-text);
         font-size:var(--font-xl-size);
         &--light {
             color:var(--text-body-text-inverse);
             text-shadow: 2px 2px 19px rgba(0,0,0,0.91);
         }
     }
     &__input-wrapper {
         position: relative;
         display: inline-block;
         width:80%;
         max-width:550px;
     }
     &__input {
         width:100%;
         padding: var(--spacing-sm) var(--spacing-xl);
         border-radius:100px;
         border: none;
         -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.33);
        box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.33);
        margin-left:auto;
        margin-right:auto;
        font-size:var(--font-lg-size);
     }
     &__input::placeholder {
        color: var(--text-body-text);
        font-size: var(--spacing-md);
      }
     &__search-button {
        position: absolute;
        right: 12px;
        background-image: none;
        border: none;
        background-color: transparent;
        top: 10px;
    }
    &__search-img {
        height: 24px !important;
        width: 24px !important;
    }
    &__search-input:focus {
      outline:0;
      border: solid 1px var(--neutral-800);
    }
}