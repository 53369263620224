.a-icon-link
{
    display: flex;
    flex-direction: row;
    font-size:var(--font-md-size);
    align-items: center;
    cursor: pointer;
    border:none;
    background-color: transparent;
    &__icon
    {
        margin-right:var(--spacing-xs);
        height:16px !important;
        flex-basis:16px;
        &--large {
            height:32px !important;
            flex-basis:32px;
        }
    }
    &__text
    {
        @include hyperlink;
        color:var(--color-action-color);
        font-weight:var(--font-default-weight);
        margin-right:var(--spacing-xs);
    }
    &--btn {
        background-color:rgba(0,0,0,0.08);
        color:var(--color-action-color) !important;
        border:none;
        display:inline-block;
        border-radius: var(--border-radius-sm);
        padding: var(--spacing-xs) var(--spacing-md);
        transition: color .2s ease-out, background-color .2s ease-out;
    }
    &--btn:hover {
        background-color:rgba(0,0,0,0.1);

        color:var(--color-action-color)!important;
        border:none;
    }
    &--inline {
        display: inline-flex;
    }

}

.a-icon-link
{

    &__text:hover
    {
        text-decoration: underline;
        color:var(--color-action-color);
        font-weight: var(--font-default-weight);
    }

}