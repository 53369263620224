.m-jump-cards {
    padding: var(--spacing-xl) 0 var(--spacing-xl) 0; // Removed right padding
    top: 0;
    z-index: 10;

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: var(--spacing-sm);
        padding: 0;
        margin: 0; // Added to ensure no margin
        list-style: none;
        width: 100%; // Added to ensure full width
    }

    &__card {
        // Adjusted calc to account for gaps more precisely
        flex: 0 0 calc((100% - (var(--spacing-sm) * 2)) / 3);
        display: block;
        background: var(--surface-body-bg);
        padding: 0;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
        text-decoration: none;
        transition: all 0.2s ease;
        border: 1px solid #eaeaea;
        position: relative;
        min-height: 100px;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
        }  
        
    }
    
    &__content {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-sm);
        padding: var(--spacing-xl);
        position: absolute;
        inset: 0;
        width: 100%;
        text-decoration: none;
    }

    &__title {
        color: var(--text-body-text);
        font-size: var(--font-lg-size);
        font-weight: var(--font-bold-weight);

        @media (max-width: 993px) {
            font-size: 1rem;
        }
    }

    &__description {
        color: var(--text-body-text-muted);
        margin: 0;
        font-size: var(--font-md-size);
        line-height: 1.5;
    }

    &__arrow {
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        width: 16px;
        height: 16px;
    }
}

/* Medium screens - 2 cards per row */
@media (max-width: 1100px) {
    .m-jump-cards__card {
        flex: 0 0 calc((100% - var(--spacing-sm)) / 2);
    }
}

/* Small screens - single column */
@media (max-width: 767px) {
    .m-jump-cards__card {
        flex: 0 0 100%;
    }
}

.m-jump-cards__card:focus-within {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    outline: 2px solid var(--focus-color, #0071bc);
    outline-offset: 2px;
    z-index: 1;
}


    /* When there's no description, center the title */
    .m-jump-cards__content--no-description {
        /* These properties center the content vertically and horizontally */
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: calc(var(--spacing-xl) + 24px); // Extra space for arrow
    }
        
        

    
    /* Adjust the title margin when no description */
        .m-jump-cards__content--no-description .m-jump-cards__title {
            margin: 0;
            width: 100%; // Ensures the title takes full width
            word-break: break-word; // Handles very long words
            overflow-wrap: break-word; // Additional support for word breakin
        }   
    
