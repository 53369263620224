.a-body-image
{
    @extend .img-fluid;
    border-radius: var(--border-radius-img-default);
    &--circle {
        border-radius:var(--border-radius-circle);
    }
    &--rounded {
        border-radius:var(--border-radius-md);
    }
    &--shadowed {
        -webkit-box-shadow: var(--shadow-light);
        box-shadow: var(--shadow-light);
    }

    &--mobile-narrow {
        @media (max-width:767px) {
            max-width: 60%;
            width: 60%;
            display: block;
        }
    }
}