/*
<div class="m-icon-block">
  <div class="m-icon-block__container">
    <img src="" alt="Info Icon" class="m-icon-block__img m-icon-block__img--32">
    <div class="m-icon-block__content">
      <h4 class="a-heading a-heading--heading-4">Concise Mode</h4>
      <p class="m-icon-block__subtitle">Operating in Concise Mode</p>
      <p class="m-icon-block__text">Claude is operating in Concise Mode. In this mode, Claude aims to reduce its output tokens while maintaining its helpfulness, quality, completeness, and accuracy.</p>
    </div>
  </div>
</div>
*/

/* SCSS */
.m-icon-block {
  border-radius: var(--border-radius-sm);
  padding: var(--spacing-md);

  &__container {
    display: flex;
    gap: var(--spacing-sm);
    align-items: flex-start;

    @media (max-width: 768px) { 

      flex-direction: column;
    }
  }

  &__img
    {
        max-width:128px !important;
        width:40% !important;
        display: block;
        margin-bottom: var(--spacing-xs);
    }
    &__img--wide
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--32
    {
        max-width:32px !important;
        width:32px !important;
    }
    &__img--x-small
    {
        max-width:48px !important;
        width:48px !important;
    }
    &__img--small
    {
        max-width:64px !important;
        width:64px !important;
    }
    &__img--medium
    {
        max-width:96px !important;
        width:96px !important;
    }
    &__img--large
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--full-width
    {
        max-width:80% !important;
        width:80% !important;
    }

  &__content {
    flex-grow: 1;
  }

       

  &__subtitle {
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 var(--spacing-sm) 0;
    color: var(--color-text-medium);
  }

  &__text {
    padding-right: var(--spacing-sm);
    white-space: pre-wrap;
    margin-bottom: var(--spacing-md);
    font-size: var(--font-md-size);
    line-height: 1.5;
    color: var(--color-text-dark);

    &:last-of-type {
      margin-bottom: var(--spacing-xs);
    }
  }
}

