.o-resource-column {
    display: flex;
    flex-direction: column;
    padding:0 0rem;
    &__highlight {
        margin-bottom:var(--spacing-xl);
    }
    &__highlight:last-of-type {
        margin-bottom:0;
    }
}