.o-live-transcript {

    background-color: var(--surface-low-contrast);
    padding:0;


    &__content {
        border: solid 1px var(--line-low-contrast);
        overflow-y: scroll;
        height: 100%;
        max-height:70vh;
        padding:var(--spacing-md);
    }
    &__search-wrapper {
        padding: 0 var(--spacing-md) var(--spacing-md) var(--spacing-md);
    }
    &__header {
        padding: var(--spacing-md);
        position: relative;
    }
    &__play-svg {
        // fill:$action-color;
        // stroke:$action-color;
        height:20px;
        width:20px;
        margin-right:var(--spacing-xxs);
        fill:0;
        margin-top:2px;
    }
    &__block {
        padding:var(--spacing-md);
        margin-bottom: 1em;
        color:var(--text-body-text);
        &--highlighted {
            background-color: var(--surface-body-bg)
        }
    }
    &__speaker {
        font-weight: var(--font-bold-weight);
        margin-bottom: .5em;
    }
    &__line {
        margin: 0;
    }
    &__ellipsis {
        text-align: left;
        margin: var(--spacing-xs);
        font-size:var(--spacing-xl);
        font-weight: var(--font-bold-weight);
        margin-left:var(--spacing-md)
    }
    &__skip-link {
        position: absolute;
        top: -9999px;
        left: -9999px;
        display: inline-block;
        padding: var(--spacing-xxs) var(--spacing-xs);
        &:focus-visible {
            top: var(--spacing-md);
            right: var(--spacing-md);
            left:auto;
            text-decoration: underline;
        }
    }
    &__time-link {
        padding:var(--spacing-xxs) var(--spacing-xs);
        background-color:var(--color-body-bg);
        border: solid 3px var(--color-action-color);
        margin-bottom:var(--spacing-xs);
        display: inline-flex;
        align-content: center

        // text-decoration: underline;
    }
    &__time-link:hover {
        text-decoration: underline;
    }

}

//REVIEW
