.m-author-highlight
{
    display:flex;
    max-width:400px;
    text-align: left;
    margin-top: var(--spacing-md);
    &--vertical {
        flex-direction: column;
        align-items: center;
    }
    &__photo
    {
       // @extend .img-fluid;
        display: block;
        object-fit: cover;
        border-radius:60px;
        max-width: 60px;
        margin-left:auto;
        margin-right:auto;
        height:auto;
        aspect-ratio: 1 / 1;
        &--large {
            max-width:128px;
            border-radius:128px;
        }
    }
    &__name
    {
        font-weight:var(--font-default-weight);
        margin-top:var(--spacing-md);
        margin-bottom: 0rem;
        margin-right: var(--spacing-md);
        margin-left: var(--spacing-md);
    }
    &__title
    {
        font-size:.8rem;
        color: var(--text-body-text-muted);
        margin-bottom: var(--spacing-xxs);
        justify-content: center;
    }
    &__bio
    {
        font-size:var(--font-md-size);
        text-align: left;
        justify-content: center;

    }
    &__social-row
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: var(--spacing-md);
    }
    &__social-row-icon
    {
        height:14px;
        width:14px;
        margin:var(--spacing-xs);
        opacity:0.6;
        transition: all ease-in .2s;
    }
    &__social-row-icon:hover
    {
        opacity: 1;
    }
}

.m-author-bio{
    display: grid;
    grid-template-columns: repeat(2, minmax(4rem, max-content));
    grid-template-rows:repeat(2, minmax(2rem, max-content));
    row-gap:var(--spacing-xxs);
    column-gap: var(--spacing-md);

    //padding-left: 2rem;

    //max-width:450px;
    text-align: left;
    margin-top: var(--spacing-md);

    &__bio
    {
        grid-column: 2/3;
        grid-row: 2/3;
        font-size:var(--font-md-size);
        text-align: left;
        //margin-left:.5rem;
        margin-top: 0;
        margin-bottom: 0;
        //align-self:center;
        //white-space: nowrap;
        align-self:start;
        line-height: 1;


    }
    &__name
    {
        grid-column: 2/3;
        grid-row: 1/2;
        font-weight:var(--font-default-weight);
        margin-top:0;
        margin-bottom: 0;
        margin-right: var(--spacing-md);
        font-weight: var(--font-bold-weight);
        align-self: end;
        line-height: var(--line-height-condensed);
    }
    &__photo
    {
       // @extend .img-fluid;
        grid-column: 1/ 2;
        grid-row: 1 / -1;
        display: block;
        object-fit: cover;
        border-radius:60px;
        max-width: 60px;
        margin-left:auto;
        margin-right:auto;
        object-fit: cover;
        align-self: center;
    }

}

.m-author-bio__row {
    display: grid;
    grid-template-columns: repeat(2, minmax(1fr, min-content));

}

//REVIEW