.o-full-hero
{
    @extend .jumbotron;
    @extend .jumbotron-fluid;
    background-size:cover;
    background-position-x: right;
    background-position-y: center;
    background-color:transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-repeat:no-repeat;
    height:35rem;
    position: relative;
    @media (min-width: 1000px) {
        max-height:55vh;
     }
    @media (max-width: 1000px) {
        padding-top:5rem;
        padding-bottom:5rem;
        min-height:23rem;
    }
    &__inner {
        display: flex;
    }

    &__inner--no-nav {
        margin-top:0px;
        display: flex;
    }
    &__overlay {
        position: absolute;
        top:0;
        left:0;
        height:100%;
        width:100%;
        background: rgb(0,0,0);
        background: var(--overlay-image-gradient);
        z-index: 0;
    }
    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__img {
        border-radius:500px;
        max-width:130px;
        margin-bottom:var(--spacing-md);
        flex-basis:130px;
        margin-right:var(--spacing-xxl);
        width:130px;
        height:130px;
    }
    &__content-wrapper
    {
        @extend .container;
        max-width:1440px;
        z-index: 1;
    }
    &__title
    {
        @include heading-1;
        color: var(--text-body-text-inverse);
        margin-bottom:var(--spacing-xl);

    }
    &__tagline
    {
        @include eyebrow--white;
        margin-bottom:0;
    }
    &__subtitle
    {
        @include subhead;
        color:var(--text-body-text-inverse);
        margin-bottom:0;
        max-width:60rem;
    }
    &__search {
        margin-top:var(--spacing-xxl);
        width:100%;
        max-width: 1140px;
    }
    &--narrow
    {
        height:30rem;
        @media (max-width:993px)
        {
            height:auto;
        }
    }
    &--extra-narrow
    {
        height:24rem;
        @media (max-width:993px)
        {
            height:auto;
        }
    }
    &--centered-bg
    {
        background-position: 50% 50%;
    }
    &--left-aligned {
        .o-full-hero__inner {
            align-items: flex-start;
            width:60%;
        }
        .o-full-hero__text {
            width:100%;
        }
    }
    &--centered {
        .o-full-hero__inner {
            flex-direction: column;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
    }
}