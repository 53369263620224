.o-full-bleed-slider {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    &__item {
        flex-basis:1;
        flex-grow: 1;
        margin:0 0 0 .75rem;
    }
    &__item:first-of-type {
        margin:0 .75rem 0 0;
    }
    &__item:last-of-type {
        margin:0 .75rem 0 0.75rem;
    }
}