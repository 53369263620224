.m-cta-card
{
    border-radius:6px;
    // border:solid 1px #dedede;
    
    padding: .25rem .5rem;
    background-color:white;
    max-width: 500px;
    &--beige
    {
        background-color:#FBF6F4;
    }
    &--gray
    {
        background-color:$lightest-gray;
    }
    &--centered
    {
        text-align:center;
    }
    &--shadowed
    {
        @include shadowed;
    }
    &__image
    {
        margin-left:auto !important;
        margin-right:auto !important;
        width:128px !important;
        display:block;
        padding-top:1rem;
    }
    &__body
    {
        @extend .card-body;
        position: relative;
        padding-top:1.5rem;
    }
    &__title
    {
        @extend .card-title;
    }
    &__text
    {
        @extend .card-text; 
    }
    &__link
    {
        color: $action-color;
        font-weight:500;
    }
    &__tag
    {
        background-color:$light-gray;
        color:$dark-gray;
        position: absolute;
        top:-1rem;
        left:1.25rem;
        padding: .25rem .5rem;
        font-size:.8rem;
        text-transform: uppercase;
        font-weight:500;
        border-radius:4px;
        border:solid 2px white;
    }
}