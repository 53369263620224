.m-page-header
{
    text-align: center;
    width:100%;
    &__title
    {
        @include heading-1;
        font-family: var(--heading-fonts);
        text-align: center;
        padding-bottom: var(--spacing-xs);
        font-weight: var(--font-bold-weight);
        letter-spacing:-.08rem;

        @media (max-width:993px)
        {
            font-size:var(--font-3xl-size);
        }
        // @extend .underline-center;

    }
    &__eyebrow {
        @include eyebrow--light-blue;
        display: inline-block;
    }
    &__subheader
    {
        text-align: center;
        margin-top:var(--spacing-xs);
        padding-bottom: 3.0rem;
        // @extend .underline-center;
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 128px auto;
        font-size: var(--font-xl-size);
        color:var(--text-body-text-inverse);
        @media (max-width:993px)
        {
            font-size:var(--font-3xl-size);
            font-size:var(--font-lg-size);
            line-height:var(--line-height-default);
        }
        &--no-hr
        {
            background-image:none;
            padding-bottom:0rem;
        }
    }
    &--left-aligned {
        text-align: left;
        .m-page-header__title {
            text-align: left;
        }
        .m-page-header__eyebrow {
            text-align: left;
        }
        .m-page-header__subheader {
            text-align: left;
        }
    }
}