.m-date-time
{
    display: flex;
    flex-direction: row;
    
    &__date {
        margin-right:var(--spacing-xxl);
    }
    &__date-label {
        font-size:var(--spacing-lg);
        color:var(--neutral-700);
        line-height: var(--line-height-condensed);
    }
    &__date-img {
        width:24px;
        height:auto;
        opacity: 0.4;
        margin-right:var(--spacing-xxs);
        margin-top: -6px;
    }
    &__time-label {
        font-size:var(--font-lg-size);
        color:var(--neutral-700);
        line-height: var(--line-height-condensed);
    }
    &__time-img {
        width:24px;
        height:auto;
        opacity: 0.4;
        margin-right:var(--spacing-xxs);
        margin-top: -6px;
    }
    &--small {
        .m-date-time__date-label {
            font-size:var(--font-md-size);
        }
        .m-date-time__time-label {
            font-size:var(--font-md-size);
        }
        .m-date-time__date-img {
            width:16px;
            margin-top: -4px;
            margin-right:0.1rem;
        } 
        .m-date-time__time-img {
            width:16px;
            margin-top: -4px;
            margin-right:0.1rem;
        }
        .m-date-time__date {
            margin-right:16px;
        }  
    }
}