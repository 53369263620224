.a-filter-item {
    display:inline-block;
    padding: 0.25rem 0.5rem;
    border:solid 2px $dark-blue;
    background-color:white;
    color:$dark-blue;
    border-radius:100px;
    font-size:0.8rem;
    font-weight:400;
    -webkit-box-shadow: 2px 2px 2px -2px rgba(0,0,0,0.6); 
    box-shadow: 2px 2px 2px -2px rgba(0,0,0,0.6);
    &--blue {
        border: solid 2px $bright-blue;
        color: $bright-blue;
    }
    &--active {
        background-color:$dark-blue;
        color:rgba(255,255,255,0.8);
        transition: all .2s ease-out;
        border:solid 2px white;
    }
    &--active:hover {
        color:rgba(255,255,255,1);
    }
}

.a-filter-item--blue.a-filter-item--active {
    background-color: $bright-blue;
}