.m-card
{
    @extend .card;
    @include shadowed;
    border-radius:5;
    border:none;
    // max-width:500px;
    &__video-wrapper {
        height:250px;
        background-color:$light-gray;
        position: relative;
        overflow:hidden;
    }
    &__video {
        position: absolute;
        top:0;
        left:0;
        bottom:0;
        right:0;
    }
    &__image
    {
        @extend .card-img-top;
        height:250px;
        object-fit: cover;
        font-family: 'object-fit: cover;';
        
    }
    &__body
    {
        @extend .card-body;
        position: relative;
        padding-top:1.5rem;
    }
    &__title
    {
        @extend .card-title;
        font-family: $body-font;
        font-size:1.25rem;
        font-weight: 500;
        line-height: 1.2;
        margin-top:0;
    }
    &__text
    {
        @extend .card-text;
        font-size:1rem;
    }
    &__link
    {
        color: $action-color;
        font-weight:500;
    }
    &__tag
    {
        background-color:$light-gray;
        color:$dark-gray;
        position: relative;
        top: -2.4rem;
        left:0rem;
        padding: .25rem .5rem;
        font-size:.7rem;
        text-transform: capitalize;
        font-weight:500;
        border-radius:400px;
        border:solid 2px white;
        -webkit-box-shadow: 2px 2px 2px -2px rgba(0,0,0,0.6); 
        box-shadow: 2px 2px 2px -2px rgba(0,0,0,0.6);
        &--purple {
            background-color:$dark-blue;
            color:white
        }
        &--blue {
            background-color:$bright-blue;
            color:white
        }
    }
    &__action-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top:auto;
    }
    &__action-btn {
        background-color:$lightest-gray;
        flex-grow:1;
        padding:0.5rem 1rem;
        text-align: center;
        font-size:0.9rem;
        border-radius:6px;
        margin-left:2.5px;
        margin-right:2.5px;
        transition: all .3s ease-out;
        color:$dark-gray;
    }
    &__action-btn:first-of-type {
        margin-left:0px;
    }
    &__action-btn:last-of-type {
        margin-right:0px;
    }
    &__action-btn:hover {
        background-color:darken($lightest-gray, 5%);
        color:$dark-gray;
        font-weight:500;
    }
}