.o-block-slider {
    padding-bottom:40px !important;
    &__block {
        margin:0 1rem;
        transition: all 0.2s ease-out;
    }
    &__block:first-of-type {
        margin:0 1rem 0 0;
    }
    &__block:last-of-type {
        margin:0 1rem 0 0;
    }
    &__block:hover {
        -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15);
        box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15);
        transition: all 0.2s ease-out;
        margin-top:-5px;
        .m-resource-highlight-v2__cta {
            color:$action-color;
            font-weight:$bold;
        }
    }
    &__prev {
        position: absolute !important;
        bottom:0px !important;
        right:32px !important;
        left:inherit !important;
        top:inherit !important;
        height:32px !important;
        width:auto !important;
        font-size:24px !important;

    }
    &__prev::after {
        font-weight:900;
        font-size:24px !important;
        color:$action-color;
    }
    &__next {
        position: absolute !important;
        bottom:0px !important;
        right:0px !important;
        left:inherit !important;
        top:inherit !important;
        height:32px !important;
        width:auto !important;
        font-size:24px !important;

    }
    &__next::after {
        font-weight:900;
        font-size:24px !important;
        color:$action-color;
    }
    &__pagination {

    }
    .swiper-pagination {
        bottom:3px !important;
    }

    .swiper-pagination-bullet-active {
        background-color:$action-color !important;
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
        opacity: 0.35 !important;
        fill:$dark-gray !important;
        color:$dark-gray !important;
    }
    .swiper-button-next.swiper-button-disabled:after, .swiper-button-prev.swiper-button-disabled:after {
        fill:$dark-gray !important;
        color:$dark-gray !important;
    }
}



