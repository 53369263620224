.a-button
{
    @extend .btn;
    color:var(--white);
    background-color:var(--color-action-color);
    font-weight:var(--font-bold-weight);
    border-radius:var(--border-radius-btn-default);
    padding:var(--spacing-xs) var(--spacing-xl);
    border:solid 2px var(--color-action-color);
    cursor: pointer;
    transition: background-color 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;
    &--blue {
        background-color:var(--bright-blue);
        border:solid 1px var(--bright-blue);
    }
    &--square {
        border-radius: 0;
    }
    &--outline {
        border: solid 2px var(--color-action-color);
        color:var(--color-action-color) !important;
        background-color: var(--white);
    }
    &--outline:hover {
        border: solid 2px var(--color-action-color);
        color:var(--white) !important;
        background-color: var(--color-action-color) !important;
    }
    &--secondary {
        background-color:var(--neutral-300);
        color:var(--color-action-color) !important;
        border:none;
    }
    &--secondary:hover {
        background-color:var(--black-80-opacity);
        background-color:var(--color-action-color);
        color:var(--white) !important;
        border:none;
    }
    &--external::after {
        content: " ↗";
        font-size:var(--font-base-size);
        font-weight: var(--font-bold-weight);
        display: inline-block;
        padding-left:.5rem;
        opacity: 0.75;
    }

    &--small {
        padding: .4rem var(--spacing-md);
    }
    &--full-width {
        width: 100%;
    }
    &--large {
        padding:var(--spacing-md) var(--spacing-xxl);
    }
    &--link {
        background-color: transparent;
        border:none;
        padding: var(--spacing-xxs);
        color:var(--color-action-color);
        &:hover {
            border:none !important;
            text-decoration: underline;
            background-color: transparent;
        }
    }
}

.a-button:active, .a-button:hover
{
    color:var(--color-action-color) !important;
    background-color:var(--white);
    border:solid 2px var(--color-action-color);
}

.a-button--outline:hover {
    color:var(--white) !important;
}
.a-button:focus {
    outline:none;
    box-shadow: none;
}

.a-button:focus-visible {
    @include focused-offset;
    box-shadow: none;
}

[data-theme="academy"] {

    .o-footer__button {
        background-color: var(--color-light-accent-color) ;
        border-color: var(--color-light-accent-color);
        color: var(--dark-sapphire) !important;
        &:hover {
            background-color: #ffffff !important;
            color: var(--dark-sapphire) !important;
            border-color: var(--color-light-accent-color) !important;
        }
    }
}