
    .o-drawer:not(.o-drawer--off-canvas) {
        @extend .l-one-thirds-col;
        &__inner {
            padding-left: var(--spacing-xxs);
            padding-right: var(--spacing-xxs);
        }
        &__fixed-btn-row {
            margin-right:var(--spacing-xxs);
        }

    }

@media (max-width: 767px) {
    .o-drawer {
        position: relative;
        transition: all 0.3s ease-out;

        &__overlay {
            height:0px;
            width:0;
            opacity: 0;
            transition: opacity 0.5s ease-out 0s, height 0s ease-out 0.5s, width 0s ease-out 0.5s;
        }
        &__content {
            display: none;
        }

        &__wrapper {
            transform: translateX(700px);
            transition: all 0.4s ease-out;
            box-shadow:  none;
            -webkit-box-shadow:none;
            -moz-box-shadow: none;
            // position: relative;

            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            position: fixed;
            right:0px;
            top:0;
            height:100%;
            width:700px;
            background: var(--surface-body-bg);
            padding:var(--spacing-xxl);
            max-width:80%;
            overflow-y:scroll;
            z-index: 2001;
            transition: all 0.4s ease-out;
        }
        &__inner {
            overflow-y: scroll;
            height: calc(100% - 110px);
            padding-left:var(--spacing-xxs);
            padding-right: var(--spacing-xxs);
            padding-bottom:200px;

        }
        &__fixed-btn-row {
            position: fixed;
            padding:var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
            bottom:0px;
            right:1rem;
            width:calc(100% - 4rem);
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.9) 89%, rgba(255,255,255,0.5) 100%);
        }
        &--left {
            .o-drawer__wrapper {
                transform: translateX(-700px);
                left:0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: var(--border-radius-lg);
                border-bottom-right-radius: var(--border-radius-lg);
            }
        }
        &--left-narrow {
            .o-drawer__wrapper {
                transform: translateX(-500px);
                width:500px;
                left:0px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: var(--border-radius-lg);
                border-bottom-right-radius: var(--border-radius-lg);
            }
        }
        &--has-button-row {
            .o-drawer__inner {
                padding-bottom:calc(100% - 82px);
            }
        }
        &--shown {
            display: block;
            .o-drawer__content {
                display: block;
                height: 100%;
                overflow-y: scroll;
            }
            .o-drawer__overlay {
                opacity: 1;
                height:100vh;
                width:100vw;
                background-color: rgba(0,0,0,0.4);
                z-index:2000;
                position: fixed;
                top:0;
                left:0;
                transition: opacity 0.4s ease-out 0.2s, height 0s ease-out 0s, width 0s ease-out 0s;


            }
            .o-drawer__wrapper {
               transform: translateX(0px);

                -webkit-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
                -moz-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
                box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
            }
        }
    }

 }

 .o-drawer.o-drawer--off-canvas {
    position: relative;
    transition: all 0.3s ease-out;

    .o-drawer__overlay {
        height:0px;
        width:0;
        opacity: 0;
        transition: opacity 0.5s ease-out 0s, height 0s ease-out 0.5s, width 0s ease-out 0.5s;
    }
    .o-drawer__content {
        display: none;
    }

    .o-drawer__wrapper {
        transform: translateX(700px);
        transition: all 0.4s ease-out;
        box-shadow:  none;
        -webkit-box-shadow:none;
        -moz-box-shadow: none;
        // position: relative;

        border-top-left-radius: var(--border-radius-lg);
        border-bottom-left-radius: var(--border-radius-lg);
        position: fixed;
        right:0px;
        top:0;
        height:100%;
        width:700px;
        background: var(--surface-body-bg);
        padding:var(--spacing-xxl);
        max-width:80%;

        z-index: 2001;
        transition: all 0.4s ease-out;
    }
    .o-drawer__inner {
        overflow-y: scroll;
        height: calc(100% - 110px);
        padding-left:var(--spacing-xxs);
        padding-right:var(--spacing-xxs);
        padding-bottom:200px;
    }
    .o-drawer__fixed-btn-row {
        position: fixed;
        padding:var(--spacing-xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
        bottom:0px;
        right:1rem;
        width:calc(100% - 2rem);

        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 54%, rgba(255,255,255,0.9) 89%, rgba(255,255,255,0.5) 100%);
    }
    .o-drawer--left {
        .o-drawer__wrapper {
            transform: translateX(-700px);
            left:0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: var(--border-radius-lg);
            border-bottom-right-radius: var(--border-radius-lg);
        }
    }
    .o-drawer--left-narrow {
        .o-drawer__wrapper {
            transform: translateX(-500px);
            width:500px;
            left:0px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: var(--border-radius-lg);
            border-bottom-right-radius: var(--border-radius-lg);
        }
    }
    .o-drawer--narrow {
        .o-drawer__wrapper {
            transform: translateX(500px);
            width:500px;
            left:0px;
            border-top-left-radius: var(--border-radius-lg);
            border-bottom-left-radius:1 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
    .o-drawer--has-button-row {
        .o-drawer__inner {
            padding-bottom:calc(100% - 82px);
        }
    }

}
.o-drawer--shown.o-drawer--off-canvas {
    display: block;
    .o-drawer__content {
        display: block;
        height: 100%;
        padding-bottom: var(--spacing-md);
        // overflow-y: scroll;
    }
    .o-drawer__overlay {
        opacity: 1;
        height:100vh;
        width:100vw;
        background-color: rgba(0,0,0,0.4);
        z-index:2000;
        position: fixed;
        top:0;
        left:0;
        transition: opacity 0.4s ease-out 0.2s, height 0s ease-out 0s, width 0s ease-out 0s;


    }
    .o-drawer__wrapper {
       transform: translateX(0px);
        transition: all 0.4s ease-out;
        -webkit-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
        -moz-box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);
        box-shadow: 0px 2px 38px -9px rgba(0,0,0,0.35);

    }
}

body:has(.o-drawer--shown) {
    overflow: hidden;
}