.o-person-grid
{
    @extend .row;
    &__wrapper
    {
        @extend .col-md-12;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        @media (max-width:993px)
        {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    &__person-highlight
    {
        margin: 1rem 0.5rem;
        flex-basis:180px;
        @media (max-width:993px)
        {
            margin-top:5rem;
            margin-left:.5rem;
            margin-right:.5rem;
            flex-basis:auto;
            width:65%;
        }
    }
    &__person-highlight:first-of-type
    {
        @media (max-width:993px)
        {
            margin-top:0rem;
        }
    } 
}