.a-resource-headline {
    &__tag {
        display: block;
        // text-transform: uppercase;
        font-size:var(--font-sm-size);
        font-weight:var(--font-default-weight);
        color: var(--neutral-700);
    }
    &__title {
        display: block;
        font-family: var(--heading-fonts);
        font-size:var(--font-md-size);
        color:var(--neutral-800);
        font-weight:var(--font-bold-weight);
        line-height: var(--line-height-default);
    }
}

.a-resource-headline:hover {
    .a-resource-headline__tag {
       
        &--yellow {
            color:var(--bright-sunflower);
        }
        &--purple {
            color:var(--purple);
        }
        &--red {
            color: var(--pumpkin-orange);
        }
        &--green {
            color:var(--rich-shamrock);
        }
        &--blue {
            color:var(--bright-blue);
        }
    }

}