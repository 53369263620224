.m-checklist
{
    @include body;
    list-style: outside;
    list-style-type: disc;
    padding:0;
    margin-left:30px;
    &__item
    {
        margin-bottom:var(--spacing-xxs);
        //position: relative;
        &>ul {
            margin-top:var(--spacing-xs);
            //>li::before {
             //   background-image:url('../images/right-arrow-green.png') !important;
            //}
        }
    }
    &__item--bold {
        font-weight:var(--font-default-weight);
    }
    &__item--doc {
        list-style: none;
        position: relative;
    }
    &__item--video {
        list-style: none;
        position: relative;
    }
    &__item--howto {
        list-style: none;
        position: relative;
    }
    &__item--policy {
        list-style: none;
        position: relative;
    }
    &__item--event {
        list-style: none;
        position: relative;
    }
    &__item--webinar-replay {
        list-style: none;
        position: relative;
    }
    &__item--manual {
        list-style: none;
        position: relative;
    }
    &__item--podcast {
        list-style: none;
        position: relative;
    }
    &__item--media {
        list-style: none;
        position: relative;
    }

    &__item--doc:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-document.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--video:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-video.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--howto:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-howto.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--manual:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-manual.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--policy:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-policy.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--podcast:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-podcast.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--event:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-event.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--webinar-replay:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-webinar-replay.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }
    &__item--media:before
    {
        content:'';
        display:inline-block;
        height:1em;
        width:1em;
        background-image:url('../images/icon-media.png');
        background-size:contain;
        background-repeat:no-repeat;
        position: absolute;
        left:-20px;
        top:3px;
    }

}