.o-filter-manager {
    display: flex;
    width:100%;
    &__btn {
        margin-right:var(--spacing-md);
        display: block;
    }
    &__terms {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__term {
        margin-right:var(--spacing-xs);
        margin-top:var(--spacing-xxs);
        margin-bottom:var(--spacing-xxs);
        @media (max-width: 767px) {
            display: none;
        }
    }
    &__clear-btn {
        background-color: transparent;
        border:none;
        color:var(--color-action-color);
        text-decoration: underline;
        display: inline-block;
        margin-left:var(--spacing-xs);
    }
    &__clear-btn:focus {
        @include focused-no-offset;
    }
    &__clear-btn::after {
        content: '⟲';
        font-size:var(--font-xl-size);
        font-weight:100;
        padding-left:var(--spacing-xxs);
        text-decoration: none;
        display: inline-block;
    }
    &__btn {
        display: none;
        @media (max-width: 767px) {
            display: block;
        }
    }
    &__more-btn:focus {
        @include focused-no-offset;
    }
}