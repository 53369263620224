.m-search-input {
    display: flex;
    align-items: center;
    &__input {
        margin-right:0rem;
        padding:var(--spacing-xs);
        font-weight:var(--font-default-weight);
        border-radius:0px;
        border:solid 1px var(--line-low-contrast);
        transition: border .3s ease-out;
        width:100%;
        &:hover {
            @include focused-offset;
        }
    }
    &__btn {
        height:42px;
        margin-left:var(--spacing-xs);
        &:hover {
            .m-search-input__icon {
                fill:var(--color-action-color);
            }
        }
    }
    &__icon {
        height:20px;
        width:20px;
        fill:var(--color-light-accent-color);
        margin-top:-6px;
    }

}