.m-paging-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--spacing-md);
    
    &__prev {
        background-color: var(--color-action-color-complement);
        border: solid 2px  var(--color-action-color);
        flex-basis: 48px;
        width: 48px;
        height: 48px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-out;
    }
    &__prev-img {
        // transform:rotate(270deg);
        width:24px;
        height: auto;
        fill:var(--color-action-color);
        transition: all 0.3s ease-out;
    }
    &__next {
        background-color: var(--color-action-color-complement);
        border:solid 2px var(--color-action-color);
        flex-basis:48px;
        width:48px;
        height:48px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s ease-out;
    }
    &__next-img {
        // transform:rotate(90deg);
        width:24px;
        height: auto;
        transform: rotate(180deg);
        fill:var(--color-action-color);
        transition: all 0.3s ease-out;
    }
    &__prev:hover {
        background-color:var(--color-action-color);
        border:solid 2px var(--color-action-color);
        transition: all 0.3s ease-out;
        .m-paging-row__prev-img {
            fill: var(--color-action-color-complement);
        }
    }
    &__next:hover {
        background-color:var(--color-action-color);
        border:solid 2px var(--color-action-color);
        transition: all 0.3s ease-out;
        .m-paging-row__next-img {
            fill: var(--color-action-color-complement); 
        }
    }
}

//REVIEW