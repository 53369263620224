.m-insight-card
{
    @extend .card;
    @include shadowed;
    border-radius:5;
    border:none;
    // max-width:500px;
    &__image
    {
        @extend .card-img-top;
        height:250px;
        object-fit: cover;
        
    }
    &__body
    {
        @extend .card-body;
        position: relative;
        padding-top:1.5rem;
    }
    &__title
    {
        @extend .card-title;
        font-size:1.25rem;
    }
    &__text
    {
        @extend .card-text; 
    }
    &__link
    {
        color: $action-color;
    }
    &__tag
    {
        background-color:$light-gray;
        color:$dark-gray;
        position: absolute;
        top:-1rem;
        left:1.25rem;
        padding: .25rem .5rem;
        font-size:.8rem;
        text-transform: uppercase;
        font-weight:500;
        border-radius:4px;
        border:solid 2px white;
    }
}

.m-insight-card--large
{
    flex-direction:row;
    &__image
    {
        flex-basis:50%;
    }
    &__body
    {
        @extend .card-body;
        position: relative;
        padding-top:1.5rem;
    }
    &__title
    {
        @extend .card-title;
        font-size:1.25rem;
    }
    &__text
    {
        @extend .card-text; 
    }
    &__link
    {
        color: $action-color;
        font-weight:500;
    }
    &__tag
    {
        background-color:$light-gray;
        color:$dark-gray;
        position: absolute;
        top:-1rem;
        left:1.25rem;
        padding: .25rem .5rem;
        font-size:.8rem;
        text-transform: uppercase;
        font-weight:500;
        border-radius:4px;
        border:solid 2px white;
    }
}