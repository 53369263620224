.m-details-block
{

    &__img
    {
        max-width:128px !important;
        width:40% !important;
        display: block;
        margin-bottom: var(--spacing-xs);
    }
    &__img--wide
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--32
    {
        max-width:32px !important;
        width:32px !important;
    }
    &__img--x-small
    {
        max-width:48px !important;
        width:48px !important;
    }
    &__img--small
    {
        max-width:64px !important;
        width:64px !important;
    }
    &__img--medium
    {
        max-width:96px !important;
        width:96px !important;
    }
    &__img--large
    {
        max-width:70% !important;
        width:70% !important;
    }
    &__img--full-width
    {
        max-width:80% !important;
        width:80% !important;
    }

    &__title
    {
        @include heading-4;
        &--large {
            @include heading-3;
        }
        &--quote {
            @include pull-quote;
        }
        &--heading-2 {
            @include heading-2;
        }
        &--heading-3 {
            @include heading-3;
        }
        &--heading-4 {
            @include heading-4;
        }
        &--heading-5 {
            @include heading-5;
        }

    }
    &__list {
        @include body;
        padding-right:var(--spacing-sm);
        margin-top:var(--spacing-md);
    }
    &__list-item {
        margin-bottom:var(--spacing-md);
    }

    &__text
    {
        @include body;
        padding-right:var(--spacing-sm);
        white-space: pre-wrap;
        &--subhead {
            @include subhead;
        }
    }

    &__text:last-of-type
    {
        margin-bottom:var(--spacing-xs);
    }
    &__link
    {
        @include hyperlink;
        margin-top:var(--spacing-md);
        display: inline-block;
        color:var(--color-action-color);
        text-decoration: underline;
        &:hover {
            text-decoration: underline;
            color:var(--color-action-color-hover);
        }
    }
    &__subtitle
    {
        @include eyebrow;
        margin-bottom:0;
        padding:0 0;
        display: inline-block;
        border-radius:var(--border-radius-sm);

        &--icon-matched {
            color: var(--color-light-accent-color);
        }
    }
    &--centered
    {
        text-align: center;
        .m-details-block__img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &--featured {
        .m-details-block__link {
            font-size:var(--font-lg-size);
            font-weight:var(--font-bold-weight);
            text-decoration:underline;
        }
    }
}
