.o-half-hero {
    @extend .jumbotron;
    padding-top:0;
    padding-bottom:0;
    padding-left:0;
    padding-right:0;
    background-color: var(--surface-body-bg);
    margin-bottom:0;
    overflow-x: hidden;
    &__title {
        @include heading-1;
        line-height: var(--line-height-condensed);
        color:var(--text-body-text);
        margin-bottom:var(--spacing-xl);

    }
    &__text-col {
        padding:var(--spacing-3xl) 0 var(--spacing-xxl) 0;
        padding-left: 5%;
        padding-right:5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--surface-low-contrast);
        @media (max-width:767px)
        {
            padding-bottom:var(--spacing-xxl);
        }
    }
    &__img-col {
        // height:50vh;
        padding-left:0;
        background-color: var(--surface-low-contrast);
        @media (max-width:767px)
        {
            margin-top:0px !important;
        }
    }
    &__intro {
        @include body;
    }
    &__tag {
        @include eyebrow;
    }
    &__featured-img {
        // max-height:50vh;
        width:auto;
        object-fit: cover;
        height:100%;
        width:100%;
        aspect-ratio: 16 / 9;
    }
    &__timestamp {
        @include subhead;
        margin-bottom:var(--spacing-xxs);
        display: block;
        margin-top:var(--spacing-md);
    }
    &__timestamp-img {
        height: 24px;
        width: 24px;
        margin: 8px 8px 8px 0;
    }
    &__cta {
        align-self: flex-start;
        margin-top:var(--spacing-xs);
    }
    &--two-thirds
    {
        .o-half-hero__text-col {
            padding-left:0px;
            padding-top:var(--spacing-xxl)
        }
    }
}

//REVIEW