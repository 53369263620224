.m-registered-block {
    display: flex;
    flex-direction: row;
    background-color: var(--surface-low-contrast);
    padding: var(--spacing-sm);
    border-radius: 6px;
    &__img {
        width:32px;
        height:auto;
        flex-basis: 24px;
        object-fit: contain;
        margin-right:var(--spacing-xs);
    }
    &__label {  
        margin-bottom: 0;
    }
    &__undo {
        font-weight:var(--font-bold-weight);
        display: block;
    }
}