// Calendar Styles
.calendar__nav {
    display: flex;
    justify-content: center;
    a {
        margin:1rem 2rem;
    }
    a:first-of-type::before {
        content: "\2039";
        margin-right: 8px;
        font-weight:900;
        font-size:24px;
    }
    a:last-of-type::after {
        content: "\203A";
        margin-left: 8px;
        font-weight:900;
        font-size:24px;
    }
}
#month {
    width:100% !important;
    table-layout: fixed;
    tbody {
        td {
            border:solid 1px $light-gray;
            position: relative;
            padding:1rem 8px; 
            .dateLink {
                position: absolute;
                top:8px;
                left:8px;
                font-weight:400;
                color:$dark-gray;
            }
        }
        .date {
            vertical-align: top;
            
            &>div {
                width: 90% !important;
            }
        }
        div.event {
            font-size:14px;
            position: relative;
            background-color:$lightest-gray;
            padding: .35rem .6rem;
            border-radius: 4px;
            span.event-start-time {
                display: block;
                color:$dark-gray;
                font-weight:400;
                font-size:12px;
            }
            span.event-topic {
                display: block;
                color:$eyebrow;
                font-weight:400;
                font-size:12px;
            }
            a {
                // color:white;
                transition: all .2s ease-out;
                line-height: 1.3;
            }
            a:hover {
                // color:white;
                transition: all .3s ease-in;
                color:$action-color;
                // text-decoration: underline;
                line-height: 1.3;
                
            }

            margin-bottom:1rem !important;
            display: block;
            
            // border-radius:10px;
        }
        div.event:hover {
            transition: all .3s ease-in;
            -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15); 
            box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.15);
            
        }
    }
    td[class="date"] {
        height:100px !important;
    }
}


// Form Styles
input[type=text].sq-form-field,textarea.sq-form-field {
    background-color: rgba(0,0,0,0.065);
    padding:.5rem;
    font-weight:300;
    border-radius:6px;
    border:solid 1px $light-gray;
    transition: all .3s ease-out;
    // width:100%;
    
}

input[type=text].sq-form-field:focus,textarea.sq-form-field:focus {
    border:solid 1px $action-color;
    outline:0;
}

.sq-form-question {
    margin-bottom:1.5rem;
}


.sq-form-question-answer ul {
    list-style: none;
    padding-left:0;
}

select.sq-form-field {
    padding:.5rem;
    background-color: rgba(0,0,0,0.065);
    font-weight:300;
    border-radius:6px;
    border:solid 1px $light-gray;
    transition: all .3s ease-out;
    
    &__option
    {
        color:$dark-gray;
        font-weight:300;
    }
}

.sq-form-submit {
    @extend .a-button;
}

legend.sq-form-question-title {
    font-size:1.25rem;
    font-weight:500;
}

legend.sq-form-section-title {
    font-size:1.75rem;
    font-weight:500;
    // margin-top:2rem;
}
legend.sq-form-section-title:first-child {
    // margin-top:0;
}
.sq-form-section {
     margin-top:2rem;
 }

 .sq-form-section:first-of-type {
    margin-top:0rem;
}

.error {
    color:$warning;
    font-weight:400;
}

.authors-wrapper {
    display: flex;
    justify-content: flex-start;
    @media (max-width: 1100px) { 
        flex-direction: column;
     }
    .m-byline {
        margin-right:24px;
    }
    .m-byline:last-of-type {
        margin-right:0px;
    }
}
