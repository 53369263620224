.m-format-toggle {
    display: flex;
    justify-content: flex-end;
    &__label {
        padding:var(--spacing-xxs) var(--spacing-xs);
        border-right:solid 1px var(--line-low-contrast);
    }
    &__option {
        margin-right:0.1rem;
        padding:var(--spacing-xxs) var(--spacing-md);
        &--active {
            background-color:rgba(0,0,0,0.1);
            font-weight:var(--font-bold-weight);
        }
    }

}