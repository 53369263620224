.m-heading-row {
    display: flex;
    align-items: center;
    padding-top:var(--spacing-md);
    padding-bottom:var(--spacing-md);
    padding-left:var(--spacing-xxs);
    padding-right: var(--spacing-xxs);
    // border-bottom: solid 1px $light-gray;
    &__title {
        flex-basis:75%;
        width:75%;
    }
    &__actions {
        flex-basis:25%;
        width:25%;
        &--mobile-only {
            @media (min-width:768px)
            {
                display: none;
            }
        }
    }
    &__actions-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__action-item {
        display: inline-block;
    }
}