.m-color-block {
    background:var(--neutral-100);

    padding:var(--spacing-xxl) var(--spacing-xxl);
    border-radius:0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border:solid 3px var(--white);
    // border:solid 1px rgba(0, 42, 93, 0.3);
    // border:solid 1px $light-gray;
    transition: all 1s ease-out;
    color:var(--neutral-800);
    &__color-bar {
        background-color:var(--neutral-800);
        color:var(--white);
    }
    &--green {
        background-color: var(--rich-shamrock-light);
        color:var(--white) !important;
    }
    &--blue {
        background-color: var(--bright-blue);
        color:var(--white) !important;
    }
    &--light-blue {
        background-color: var(--lightest-blue);
    }
    &--white {
        background: radial-gradient(farthest-corner at 100% 0%, #ffffff 80%, #ffffff 100%);
        transition: all 1s ease-out;
    }
    &--fixed-height {
        min-height:320px;
    }
    &--fixed-height-small {
        min-height:250px;
    }
    &__tag {
        @include eyebrow;
    }
    &__title {
        @include heading-3;

    }
    &__secondary-link {
        color:var(--color-action-color);
        font-weight: var(--font-default-weight);
        margin-bottom:3rem;
    }
    &__link {
        display: block;
        font-weight: var(--font-default-weight);
        margin-top:auto;
        color:var(--color-action-color);
    }
    &__link:last-of-type {
        margin-top:0;
    }
    &__link:first-of-type {
        margin-top:auto;
    }
    &__link:hover {
        text-decoration: underline;
    }
    &__secondary-link:hover {
        text-decoration: underline;
    }
    &__img {
        max-width:64px;
        margin-bottom:var(--spacing-md);
        width:64px;
        height:64px;
        &--small {
            max-width:24px;
            width:24px;
            height:24px;
        }
    }
}

.m-color-block:hover {
    // cursor: pointer;
    color:var(--neutral-800);
}

.m-color-block__secondary-link+.m-color-block__link:last-of-type {
    margin-top:auto;
}

.m-color-block:hover {
    transition: all .5s ease-out;
    // background: linear-gradient(30deg,rgba(0,113,188,.2) 0,rgba(0,113,188,.1) 15%,#ffffff 33%);
    /* Created with https://www.css-gradient.com */
    // background: #4A8E43;
    // background: -webkit-radial-gradient(bottom left, #4A8E43, #D4663B);
    // background: -moz-radial-gradient(bottom left, #4A8E43, #D4663B);
    // background: radial-gradient(to top right, rgba(0,113,188,.2), #ffffff);
    /* Created with https://www.css-gradient.com */
    background: radial-gradient(farthest-corner at 90% 0%, var(--white) 80%, var(----neutral-100) 100%);;
    border:solid 3px var(--deep-sea-blue-6);
}
