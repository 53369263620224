/*
<div class="o-blur-hero o-blur-hero--left-aligned" style="background-image:url();">
    <div class="o-blur-hero__overlay">
        <div class="o-blur-hero__overlay-blur"></div>
    </div>
    <div class="o-blur-hero__container">
        <div class="o-blur-hero__content">
            <div class="o-blur-hero__text-wrapper">
                <p class="o-blur-hero__tagline">Lorem</p>
                <h1 class="o-blur-hero__title">Lorem Ipsum</h1>
                <p class="o-blur-hero__subtitle">Lorem Ipsum kind of long subtitle</p>
            </div>
            <div class="o-blur-hero__button-wrapper">
                <button class="a-button">Primary</button>
                <button class="a-button a-button--outline">Secondary</button>
            </div>
        </div>
    </div>
</div>
*/

/* SCSS */
.o-blur-hero {
    position: relative;
    height: 30rem;
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;

    @media (min-width: 768px) {
        max-height: 65vh;
    }
    @media (max-width: 1024px) {
        background-position: 70% center; // Slightly adjust for medium screens
    }

    @media (max-width: 768px) {
        height: auto;
        display: flex;
        flex-direction: column;
        background: none;
        position: static; // Remove relative positioning on mobile
    }

    &__overlay {
        position: absolute;
        inset: 0;


        @media (max-width: 768px) {
            position: relative;
            order: 2;
            height: 300px;
            background-image: inherit;
            background-size: cover;
            background-position: center 40%;
            overflow: hidden; // Contain the blur effect
            isolation: isolate; // Create stacking context

            @media (max-width: 480px) {
                background-position: 60% 40%;
            }

            @media (max-width: 360px) {
                background-position: 65% 40%;
            }
        }
        // Additional breakpoints for image positioning
        @media (max-width: 480px) {
            background-position: 60% 40%;
        }

        @media (max-width: 360px) {
            background-position: 65% 40%;
        }
    }

    &__overlay-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        background-color: rgba(0,0,0, 0.6);

        @media (max-width: 768px) {
            position: absolute; // Keep it absolutely positioned
            top: 0;
            left: 0;
            width: 50%;
            height: 100%; // Full height of image section only
            z-index: 1;
            display: none;
        }
    }

    &__container {
        position: relative;
        height: 100%;
        max-width: 1440px;
        margin: 0 auto;
        z-index: 1;

        @media (max-width: 768px) {
            position: static;
            height: auto;
            order: 1;
            background: white;
            isolation: isolate; // Create new stacking context
            margin: 0;
        }
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding-left: 1rem;
        position: relative; // Add relative positioning

        @media (max-width: 768px) {
            width: 100%;
            height: auto;
            padding: 2rem 1rem;
            background: white;
            position: static; // Remove positioning
            justify-content: flex-start;
        }
    }

    &__text-wrapper {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-xs);
        padding-right: var(--spacing-xxl);
    }

    &__button-wrapper {
        display: flex;
        gap: var(--spacing-md);
        margin-top: var(--spacing-xl);

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start; // Align buttons to the left
            width: auto; // Don't stretch container

        }
    }

    &__tagline {
        color: var(--text-accent-complement);
        font-weight: var(--font-bold-weight);
        margin-bottom: 0;

        @media (max-width: 768px) {
            font-weight: var(--font-bold-weight);
            color: var(--text-accent);
        }
    }

    &__title {
        @include heading-1;
        color: var(--text-body-text-inverse);
        margin-bottom: var(--spacing-xl);

        @media (max-width: 768px) {
            color: var(--text-body-text);
            margin-bottom: var(--spacing-lg);
        }
    }

    &__subtitle {
        @include subhead;
        color: var(--text-body-text-inverse);
        margin-bottom: 0;
        max-width: 50rem;

        @media (max-width: 768px) {
            color: var(--text-body-text);
            max-width: 100%;
        }
    }
}