.o-navbar {

    background-color:var(--surface-body-bg);
    -webkit-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    -moz-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    position:fixed;
    z-index:1999;
    position:sticky;
    top:0px;
    width:100%;
    &__inner {
        display: flex;
        padding:0rem 32px;
        align-items: center;
        min-height:80px;
        width:100%;
        position: relative;
        @media (max-width: $nav-breakpoint) {
            flex-direction: column;
            align-items:flex-start;
            padding: 0;
        }
        @media (min-width: $nav-breakpoint) {
            height:80px;
        }
        &--expanded {
            height:115vh;
            @media (max-width: $nav-breakpoint) {
               overflow-x: hidden;
               padding-bottom:200px;
            }
        }
    }
    &__brand {

        svg {
            fill:var(--dark-blue) !important;
            height:var(--sizing-navbar-logo);
            path {
                fill:var(--dark-blue)!important;
            }
        }
        @media (max-width: $nav-breakpoint) {
            position: absolute;
            // top: 50%;
            height: 28px;
            margin-top:26px;
            padding-left: 32px;
        }

    }
    &__logo-svg {

    }
    &__logo-svg path {

    }
    &__primary-nav-list {
        list-style:none;
        display: flex;
        margin-bottom:0;
        @media (max-width: $nav-breakpoint) {
            display: none;
            margin-top:80px;
            padding-left:0px;
            padding-right:0;
            width:100%;

        }
        @media (min-width: $nav-breakpoint) {
            // height:100%;
        }

    }
    &__nav-button{
      background: transparent;
      border: none;
      font: inherit;
      color: var(--text-body-text) !important;
      cursor: pointer;
      display: inline-block;
      color: var(--text-body-text) !important;
      @include body;
      transition: color 0.3s ease-out;
      padding:15px var(--spacing-xl);
      &:hover {
        text-decoration: underline !important;
        }
    @media (max-width: $nav-breakpoint) {
        font-size:1.3rem;
        color: var(--color-action-color) !important;
       }
    @media (min-width: $nav-breakpoint) {
        line-height: 8px;
      }

    }
    &__nav-button:focus {
        outline:none;
    }
    &__nav-button:focus-visible {
        outline-style: solid;
        outline-color: var(--color-focus);
        outline-width: 3px;
        outline-offset: 2px;


    }

    &__nav-button:hover {
        color:var(--color-action-color) !important;
        text-decoration: underline;
        @media (max-width: $nav-breakpoint) {
            color:var(--text-body-text) !important;
        }
    }

    &__nav-item {
        margin-right:var(--spacing-sm);
        line-height: var(--line-height-extra-condensed);
        border-radius: var(--border-radius-btn-default);
        @media (max-width: $nav-breakpoint) {
            line-height: 3;
            margin-left:0;
            padding-left: 0;
            margin-right:0;
        }
        &--dropdown-list {
            position: relative;
        }
        &--showing {
            .o-navbar__dropdown-wrapper {
                display: block !important;
            }
            .o-navbar__dropdown-list {
                display: block !important;
            }
        }
        &--hiding {
            .o-navbar__dropdown-wrapper {
                opacity: 0 !important;
                display: none;
            }
            .o-navbar__dropdown-list {
                opacity: 0 !important;
                display: block;
            }
        }
        &--active {
            background-color:var(--surface-low-contrast);
            @media (max-width: $nav-breakpoint) {
                background-color: transparent;
            }
            @media (min-width: $nav-breakpoint) {
                outline: 1.5px solid var(--color-action-color);
                outline-offset: -2px;
            }

            .o-navbar__nav-item-link {
                // color:white !important;
            }

            .o-navbar__dropdown-list {
                display: block !important;
                opacity: 1.0 !important;
                height: auto !important;
                transition: all 0.4s ease-out;
                transition-delay: 100ms;
                @media (max-width: $nav-breakpoint) {

                }
            }
            .o-navbar__dropdown-wrapper {
                display: block !important;
                opacity: 1.0 !important;
                height: auto !important;
                transition: all 0.4s ease-out;
                transition-delay: 100ms;
                @media (max-width: $nav-breakpoint) {
                    background-color:var(--surface-high-contrast);

                }
            }

        }
        &--search {
            display: none;
            width: 48px;
            @media (max-width: 1200px) {
                display: inline-block;
            }
        }
        &--condensed {
            margin-right:var(--spacing-sm);
        }
        &--avatar {
            margin-right:0;
            padding:var(--spacing-md);
            margin-left:var(--spacing-md);
            cursor: pointer;
            @media (max-width: $nav-breakpoint) {
                display: none;
            }
        }
        &--avatar.o-navbar__nav-item--active {
            -webkit-box-shadow: 0px 0px 32px -18px rgba(0,0,0,1);
            -moz-box-shadow: 0px 0px 32px -18px rgba(0,0,0,1);
            box-shadow: 0px 20px 22px -18px rgba(0,0,0,1);
        }
    }
    &__nav-item:hover {
        .o-navbar__avatar-img {
            border:solid 3px var(--color-action-color);
        }
    }
    &__nav-item-link {
        color: var(--text-body-text) !important;
        background-color: transparent;
        border: none;
        @include body;
        transition: color 0.3s ease-out;
        padding:15px var(--spacing-xl);
        display: inline-block;
        cursor: pointer;
        border-radius: var(--border-radius-btn-default);
        @media (max-width: $nav-breakpoint) {
            width:calc(100% - 64px);
            border-bottom: solid 1px var(--line-low-contrast);
            text-align: left;
            margin: 0 32px;
            padding:12px 0;
        }
        // outline-style: solid;
        // outline-color: transparent;
        // outline-width: 3px;
        // outline-offset: 0px;


        &:hover {
            text-decoration: underline !important;
            color: var(--color-action-color) !important;
        }
        @media (max-width: $nav-breakpoint) {
            font-size:1.3rem;
            // margin-left: -24px;

        }
        @media (min-width: $nav-breakpoint) {
            line-height: 8px;
        }
    }
    &__nav-item-link:focus {
        outline:none;
    }
    &__nav-item-link:focus-visible {
        outline-style: solid;
        outline-color: var(--color-focus);
        outline-width: 3px;
        outline-offset: 2px;


    }

    &__nav-item-link:hover {
        color:var(--color-action-color) !important;
        text-decoration: underline;
        @media (max-width: $nav-breakpoint) {
            color:var(--color-action-color) !important;
        }
    }
    &__search-form {
        margin-left:auto;
        position: relative;
        @media (max-width: $nav-breakpoint) {
            display: none;
        }

    }
    &__contact-button {
        margin-left:auto;
        position: relative;
        @media (max-width: $nav-breakpoint) {
            display: none;
            display: block;
            position: absolute;
            right: var(--navbar-contact-right-margin);
            top: 19px;
        }
    }
    &__search-link {
        margin-left:var(--spacing-xl);
        position: relative;
        @media (max-width: $nav-breakpoint) {
            display: block;
            height: 28px;
            width: 28px;
            position: absolute;
            right: 70px;
            top: 26px;
        }
    }
    &__search-icon {
        height:28px;
        width:auto;
    }

    &__search-input {
        background-color:var(--surface-low-contrast);
        border-radius: 100px;
        border: none;
        padding: var(--spacing-xs) var(--spacing-lg);
        transition: border .2s ease-out;
        font-size:var(--font-sm-size);
    }
    &__search-button {
        position: absolute;
        right: 12px;
        background-image: none;
        border: none;
        background-color: transparent;
        top: 4px;
    }
    &__search-img {
        height: 16px !important;
        width: 16px !important;
    }
    &__search-input:focus {
      outline:0;
      border: solid 1px var(--line-low-contrast);
    }

    &__search-image {
        margin-left:1rem;

        .o-navbar__search-icon-svg:hover {
            height:22px;
            width:22px;
            fill: var(--color-body-bg);
        }
    }

    &__search-icon-svg {
        height:22px;
        width:22px;
        fill: var(--color-body-bg);
    }

    &__arrow {
        width:10px;
        @media (max-width: $nav-breakpoint) {
        .o-navbar__nav-item--active & {
            transform: rotate(180deg);
            transition: transform 0.3s ease;  // Smooth rotation
        }
        }
    }

    &__dropdown-wrapper {
        display: none;
        opacity:0;
        // height:0;
        transition: all 0.4s ease-out;
        transition-delay: 100ms;
    }
    &__dropdown {
       // background-color: darken($dark-blue, 5%);
        background-color:var(--surface-high-contrast);

        width:900px;

        padding:4rem 2em 4em 2em;
        position: absolute;
        // left:0;
        left:calc(50% - 450px);
        right:0;
        top:80px;
        z-index:998;
        display: flex;
        justify-content: left;
        -webkit-box-shadow: 0px 0px 32px -18px rgba(0,0,0,1);
        -moz-box-shadow: 0px 0px 32px -18px rgba(0,0,0,1);
        box-shadow: 0px 20px 22px -18px rgba(0,0,0,1);
        // max-width:900px;
        @media (max-width: $nav-breakpoint) {
            flex-direction: column;
            background-color: transparent;
            position: static;
            // top:inherit;
            // left:inherit;
            // right:inherit;
            // z-index: inherit;
            padding-top:var(--spacing-lg);
            padding-left:32px;
            padding-bottom:var(--spacing-lg);
            padding-right:var(--spacing-sm);
            max-width: 100%;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        &--narrow {
            width: 350px;
        }
    }
    &__dropdown-list {
        //background-color: darken($dark-blue, 10%);
        background-color:var(--surface-high-contrast);

        //background-color:var(--surface-low-contrast);
        width:300px;
        padding:var(--spacing-md) var(--spacing-xl);
        position: absolute;
        left:0;
        top:95%;
        z-index:9998;
        display:none;
        -webkit-box-shadow: 0px 17px 15px 2px rgba(0,0,0,0.32);
        box-shadow: 0px 17px 15px 2px rgba(0,0,0,0.32);
        @media (max-width: $nav-breakpoint) {
            position: relative;
        }
        &--right-align {
            right:0;
            left:auto;
        }
    }
    &__dropdown-list-item {

        display:block;
        margin:var(--spacing-lg) 0rem;
        // font-size:0.9rem;
        @media (max-width: $nav-breakpoint) {
            margin:var(--spacing-xs) 0rem;
            line-height: var(--line-height-default);

        }
        &--top-hr {
            border-top:solid 1px var(--line-low-contrast);
            padding-top:var(--spacing-lg);
        }
    }
    &__dropdown-list-item-help {
        font-size:var(--font-sm-size);
        margin-top: var(--spacing-xxs);
        display: block;
        color:var(--color-body-bg);
        &--warning {
            // color:$warning;
        }
    }

    &__dropdown-list-item:last-of-type {
        margin-bottom:var(--spacing-xs);
    }
    &__dropdown-list-item:hover {
        color:var(--color-action-color) !important;
        text-decoration: underline;
        .o-navbar__dropdown-list-item-help {
            text-decoration: none !important;
        }
    }

    &__dropdown-group {
        flex-basis: 50%;
        @media (max-width: $nav-breakpoint) {
            flex-basis: inherit;
            margin-top:var(--spacing-xxl);
        }
    }
    &__dropdown-group:first-of-type {
        @media (max-width: $nav-breakpoint) {
            margin-top:0rem;
        }
    }
    &__dropdown-group:last-of-type {
        @media (max-width: $nav-breakpoint) {
            margin-bottom:0rem;
        }
    }
    &__group-title {
        font-weight:var(--font-bold-weight);
        margin-bottom: var(--spacing-sm);

        color:var(--color-light-accent-alt);
        font-size:var(--font-lg-size);
        display: block;
        line-height:1.5;
        @media (max-width: $nav-breakpoint) {
            line-height:1.5 !important;
        }
    }
    /*&__group-title:hover {
        color:var(--color-action-color-complement);
        text-decoration: underline;
        @media (max-width: $nav-breakpoint) {
            color:var(--color-action-color);
        }

    }*/
    &__subgroup-title {
        font-weight:var(--font-bold-weight);
        padding-top: var(--spacing-md);
        margin-bottom: 0rem;
        color:var(--text-accent-complement) !important;
        display: block;
        @media (max-width: $nav-breakpoint) {
            margin-bottom:0rem;
            color:var(--text-accent-complement) !important;
            padding-top: 0rem;

        }
    }
    &__subgroup-title:hover {
        color:var(--color-action-color-complement) !important;
        text-decoration: underline;
        @media (max-width: $nav-breakpoint) {
            color: var(--color-action-color-complement) !important;
        }

    }
    &__group-description {
        color:var(--text-body-text);
        margin-top:var(--spacing-xs);
        margin-bottom:var(--spacing-md);
    }
    &__dropdown-item {
        margin:var(--spacing-sm) 0rem;
        display:block;
        color:rgba(255,255,255,0.8);
        margin:var(--spacing-sm) 0rem;
        font-size:var(--font-md-size);
        transition: color .2s ease-out, text-decoration .2s ease-out;
        // margin-left:0.5rem;
        @media (max-width: $nav-breakpoint) {
            margin:var(--spacing-xs) 0rem;
            line-height: var(--line-height-default);
            /*color: var(--color-action-color);*/
        }
    }
    &__dropdown-item--indented {
        margin-left:var(--spacing-lg);

    }
    &__dropdown-item:hover {
        color:var(--text-body-text-inverse);
        text-decoration: underline;
        @media (max-width: $nav-breakpoint) {
            color:rgba(255,255,255,0.8);
        }
    }
    &__dropdown-item:first-of-type {
        // margin-top:-1rem;
    }


    &__dropdown:after {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: transparent;
        z-index: 9999;
        top: 0px;
        -webkit-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
        -moz-box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
        box-shadow: 0px 0px 22px -18px rgba(0,0,0,1);
    }


    &__avatar-img {
        width:46px;
        height:46px;
        object-fit: cover;
        border-radius:100px;
        transition: all 0.3s ease-out;
        border: solid 3px rgba(255,255,255,0);
    }
    &__avatar-badge {
        background-color:var(--color-warning);
        height:20px;
        width:20px;
        text-align: center;
        line-height:var(--line-height-condensed);
        font-weight:var(--font-bold-weight);
        font-size:var(--font-sm-size);
        padding-top:1px;
        border-radius: 100px;
        border:solid 2px var(--color-body-bg);
        position: absolute;
        top:12px;
        right:12px;
        display: block;
        color:var(--color-body-bg);
    }
    &__toggle {
        display: none;
        position: absolute;
        right:15px;
        top:26px;
        border: none;
        background-color:transparent;
        @media (max-width: $nav-breakpoint) {
            display: block;
        }
    }
    &__toggle-svg {
        height:28px;
        width:28px;
    }

}


.o-navbar__inner--expanded {
    overflow-y: scroll;
    .o-navbar__primary-nav-list {
        display: block;
    }
}

.o-navbar__dropdown-group .m-resource-highlight-v2 {
    @media (max-width: $nav-breakpoint) {
        display: none;
    }
}

.o-navbar__divider {
    display: none;
    width: 100%;
    height: 1px;
    background-color: var(--line-low-contrast);
}

 @media (max-width: $nav-breakpoint) {
    .o-navbar__group-title {
        margin-bottom: 0rem;
    }
}



@media screen and (max-width: 768px) {
    .o-nav-bar__divider {
        display: block;
    }
}


//REVIEW
