.m-cta-block
{
    text-align:center;
    &__heading
    {
        font-family:var(--heading-fonts);
        font-weight:var(--font-bold-weight);
        font-size:var(--font-xxl-size);
    }
    &__text
    {
        margin-top:var(--spacing-xs);
        margin-bottom:var(--spacing-xl);
    }
}