.m-button-row {
    display: flex;
    justify-content: flex-end;
    &__button {
        margin-right:16px;
    }
    &__button:last-of-type {
        margin-right:0;
    }
    &--left-aligned {
        justify-content: flex-start;
        .m-button-row__button {
            
        }
    }
}