.a-select-input
{
    padding:var(--spacing-xs);

    border:solid 1px var(--line-low-contrast);
    transition: border .3s ease-out;


    &--selector {
        padding:var(--spacing-sm) var(--spacing-3xl) var(--spacing-sm) var(--spacing-sm);
        font-weight:var(--font-default-weight);
        border-radius:0px;
        border:solid 1px var(--line-low-contrast);
        transition: border .3s ease-out;
        -webkit-appearance:none;
        -moz-appearance:none;
        appearance:none;
        background-image:url('../images/dropdown-arrow.png');
        background-size: 24px 24px;
        background-repeat: no-repeat;
        background-position: 95% center;
        &__option
        {
            color:var(--text-body-text);
            font-weight:var(--font-default-weight);
        }
    }
    &__option
    {
        color:var(--text-body-text);
        font-weight:var(--font-default-weight);
    }
}

.a-select-input:focus-visible
{
    @include focused-offset;
}

