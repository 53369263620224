.o-tabbed-content {
    &__list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        position: relative;
        z-index: 1;
    }

    &__button {
        border: none;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        align-items: center;
        gap: var(--spacing-xl);
        @extend .btn;
        color: var(--white);
        background-color: var(--color-action-color);
        font-size: var(--font-xl-size);
        border-radius: var(--border-radius-btn-default);
        padding: var(--spacing-xl) var(--spacing-md);
        border: solid 1px var(--color-action-color);
        border-right:none;
        transition: background-color 0.3s ease-out, color 0.3s ease-out, border 0.3s ease-out;
        &:last-child {
            border-right: solid 1px var(--color-action-color);
        }

        &:focus {
            z-index: 2;
            outline: none;
            box-shadow: none;
        }

        &:focus-visible {
            @include focused-offset;
            box-shadow: none;
        }

        &:not([aria-selected="true"]) {
            background: var(--surface-body-bg);
            color: var(--text-body-text-muted);
            text-decoration: underline;
            border: solid 1px var(--line-low-contrast);
            border-right: none;

            // Target last unselected tab within this context
            &:last-of-type {
                border-right: solid 1px var(--line-low-contrast);
            }
        }
    }

    &__chevron {
        display: none;
        width: 16px;
        height: 16px;
        transition: transform 0.3s ease;
    }

    &__panel {
        background: var(--surface-low-contrast);
        width: 100%;
        padding: var(--spacing-xl);

        &--hidden {
            display: none;
        }
    }

    // Mobile styles
    @media (max-width: 767px) {
        &__list {
            flex-direction: column;
        }

        &__button {
            width: 100%;
            text-align: left;
            flex: none;
            justify-content: space-between; /* Add this line */
            margin-bottom: 0.5rem;
            font-size: var(--font-xxl-size);
            color: var(--white);
            background-color: var(--color-action-color);
            font-weight: var(--font-bold-weight);
            border-radius: var(--border-radius-btn-default);
            padding: var(--spacing-lg) var(--spacing-lg);
            border: solid 1px var(--color-action-color);


            &[aria-expanded="true"] {
                color: var(--white) !important;
                background-color: var(--color-action-color);
                font-weight: var(--font-bold-weight);
                border-radius: var(--border-radius-btn-default);
                padding: var(--spacing-lg) var(--spacing-lg);
                border: solid 1px var(--color-action-color);
                .o-tabbed-content__chevron {
                    fill: var(--color-action-color-complement);
                }
            }

            &[aria-expanded="false"] {
                background: var(--surface-body-bg);
                color: var(--text-body-text) !important;
                font-weight: var(--font-default-weight);
                text-decoration: underline;
                border: solid 1px var(--line-low-contrast);

                // Keep default hover styles for collapsed state
            }
        }

        &__chevron {
            display: inline-block;
            width: 24px;
            height: 24px;
        }

        &__panel {
            border-bottom: 1px solid #F2F2F2;
            padding-left: var(--spacing-md);
        }
    }

    // Desktop styles
    @media (min-width: 768px) {
        &__list {
            flex-direction: row;
        }

        &__button {
            flex: 1;
        }

        &__panel {
            order: 1;
        }
    }
}

.o-tabbed-content__button:hover
{
    color:var(--color-action-color-complement);
    // background-color:var(--white);
    // border:solid 1px var(--color-action-color);
    // border-color:var(--color-action-color) !important;
}

.o-tabbed-content__button[aria-expanded="true"] .o-tabbed-content__chevron {
    transform: rotate(180deg);
  }

.o-tabbed-content__button:not([aria-selected="true"]):hover {
    color: var(--color-action-color) !important;
    // background-color: var(--white);
    // border: solid 1px var(--color-action-color);
    // border-color: var(--color-action-color) !important;
}