.m-byline {
    display: flex;
    align-items: center;
    &__author-details {
        margin-left:var(--spacing-xs);
        font-size: var(--font-sm-size);
        line-height:var(--line-height-default);
        &--large {
            font-size:var(--font-lg-size);
        }
        
    }
    &__name {
        display: block;
        margin: 0;
        line-height: var(--line-height-default);
        margin-bottom:var(--spacing-xs);
        font-weight:var(--font-bold-weight);
    }
    &__role {
        display: block;
        margin: 0;
        line-height: var(--line-height-default);
    }

    &__img {
        height:24px;
        width:24px;
        margin:var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) 0;
        border-radius:100px;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        &--large {
            height:64px;
            width:64px;
        }
    }
}