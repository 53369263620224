.a-collapse-toggle
{
    display: flex;
    flex-direction: row;
    font-size: var(--font-md-size);
    align-items: center;
    cursor: pointer;
    border: none;
    background-color: transparent;
    min-height: 44px;

    &__icon-vertical {
        transition: all 0.1s ease-out;
    }
    &--expanded {
        .a-collapse-toggle__icon-vertical {
            opacity: 0;
        }
    }
    &--inline {
        display: inline-flex;
    }
    &__icon
    {
        margin-right:var(--spacing-xs);
        height:16px !important;
        flex-basis:16px;
        fill:var(--color-action-color);
        &--medium {
            height:24px !important;
            flex-basis:24px;
        }
        &--large {
            height:32px !important;
            flex-basis:32px;
        }
    }
    &__text
    {
        @include hyperlink;
        color:var(--color-action-color);
        font-weight:var(--font-default-weight);
        margin-left:var(--spacing-xs);
    }


}

.a-collapse-toggle
{

    &__text:hover
    {
        text-decoration: underline;
        color:var(--color-action-color);
        font-weight: var(--font-default-weight);//was font-weight: 500
    }

}

.a-collapse-toggle:focus {
    outline: none;
}

.a-collapse-toggle:focus-visible {
    @include focused-offset
}
