.o-masonry-section {
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    justify-content: space-between;;
    &__block {
        flex-basis:calc(50% - 8px);
        @media (max-width:767px) {
            flex-basis:calc(100% - 8px)
        }
        margin-top:8px;
        margin-bottom:8px;
        transition: all .3s ease-out;
        &--one-fourth {
            flex-basis:calc(25% - 8px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 8px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 8px)
            }
        }
        &--one-third {
            flex-basis:calc(33.33% - 8px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 8px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 8px)
            }
        }
        &--two-third {
            flex-basis:calc(66.66% - 8px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 8px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 8px)
            }
        }
        &--three-fourth {
            flex-basis:calc(75% - 8px);
            @media (max-width:992px) {
                flex-basis:calc(50% - 8px)
            }
            @media (max-width:767px) {
                flex-basis:calc(100% - 8px)
            }
        }
        &--full-width {
            flex-basis:100%;
            padding-right:25%;
        }
    }
    &__block:hover {
        // margin-top:5px;
        // margin-bottom:15px;
       
        // -webkit-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);
        // -moz-box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);
        // box-shadow: 0px 0px 19px -4px rgba(0,0,0,0.37);

    }
}