.m-event-item {
    display: flex;
    background-color: var(--neutral-100);
    padding:var(--spacing-md) var(--spacing-xs);
    justify-content: space-between;
    border-left:solid 5px var(--deep-sea-blue);
    -webkit-box-shadow: 2px 2px 5px 1px var(--black-90-opacity); 
    box-shadow: 2px 2px 5px 1px var(--black-90-opacity);
    &--yellow {
        border-left:solid 5px var(--bright-sunflower);
    }
    &--purple {
        border-left:solid 5px var(--purple);
    }
    &--red {
        border-left:solid 5px var(--pumpkin-orange);
    }
    &--green {
        border-left:solid 5px var(--rich-shamrock-light);
    }
    &__when {
        flex-basis:20%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__month {
        display: block;
        color:var(--neutral-700);
        font-weight:var(--font-default-weight);
        line-height: var(--line-height-condensed);
    }
    &__day {
        display: block;
        color:var(--neutral-var(--font-bold-weight));
        font-weight:var(--font-bold-weight);
        line-height: var(--line-height-condensed);
        font-size:var(--font-xxl-size);
    }
    &__time {
        display: block;
        color: var(--neutral-700);
        font-weight: var(--font-default-weight);
        line-height: var(--line-height-condensed);
        font-size: var(--font-sm-size);
        margin-top:var(--spacing-xs);
    }
    &__details {
        flex-basis:50%;
    }
    &__title {
        color:var(--neutral-800);
        font-weight:var(--font-bold-weight);
        line-height: var(--line-height-condensed);
        font-size:var(--font-md-size);
        margin-bottom:var(--spacing-xs);
    }
    &__link {
        font-weight:var(--font-default-weight);
        line-height: var(--line-height-condensed);
        font-size:var(--font-md-size);
    }
    &__category {
        color: var(--deep-sea-blue);
        font-weight: var(--font-bold-weight);
        line-height: var(--line-height-condensed);
        font-size: var(--font-md-size);
    }
    &__action {
        flex-basis:25%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // border-left:solid 1px $light-gray;
        padding-left:var(--spacing-xs);
    }
}